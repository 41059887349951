import merge from 'lodash/merge';
import teamsList from 'config/constants/teams';
import { getProfileContract } from 'utils/helpers/web3/contract-helpers';
import { Team } from 'config/constants/types';
import makeBatchRequest from 'utils/makeBatchRequest';
import { TeamsById } from 'state/types';

const profileContract = getProfileContract();

export const getTeam = async (teamId: number): Promise<Team> => {
  try {
    const {
      0: teamName,
      2: numberUsers,
      3: numberPoints,
      4: isJoinable
    } = await profileContract.getTeamProfile(teamId);
    const staticTeamInfo = teamsList.find(staticTeam => staticTeam.id === teamId);

    return merge({}, staticTeamInfo, {
      isJoinable,
      name: teamName,
      users: numberUsers,
      points: numberPoints
    });
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return null;
  }
};

/**
 * Gets on-chain data and merges it with the existing static list of teams
 */
export const getTeams = async (): Promise<TeamsById> => {
  try {
    const teamsById = teamsList.reduce((accum, team) => {
      return {
        ...accum,
        [team.id]: team
      };
    }, {});
    const nbTeams = await profileContract.numberTeams();
    const calls = [];

    for (let i = 1; i <= nbTeams; i++) {
      calls.push(profileContract.getTeamProfile(i));
    }

    const teamData = (await makeBatchRequest(calls));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const onChainTeamData = teamData.reduce((accum, team, index) => {
      const { 0: teamName, 2: numberUsers, 3: numberPoints, 4: isJoinable } = team;

      return {
        ...accum,
        [index + 1]: {
          name: teamName,
          users: numberUsers.toNumber(),
          points: numberPoints.toNumber(),
          isJoinable
        }
      };
    }, {});

    return merge({}, teamsById, onChainTeamData);
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return null;
  }
};
