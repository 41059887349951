import BigNumber from 'bignumber.js';
import erc20ABI from 'config/abi/hrc20.json';
import vaultchefABI from 'config/abi/vaultChef.json';
import multicall from 'utils/multicall';
import { getAddress, getVaultChefAddress } from 'utils/addressHelpers';
import { VaultConfig } from 'config/constants/types';

export const fetchVaultUserAllowances = async (account: string, vaultsToFetch: VaultConfig[]) => {
  const vaultChefAddress = getVaultChefAddress();

  const calls = vaultsToFetch.map(vault => {
    const lpContractAddress = getAddress(vault.lpAddresses);
    return { address: lpContractAddress, name: 'allowance', params: [account, vaultChefAddress] };
  });

  const rawLpAllowances = await multicall(erc20ABI, calls);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const parsedLpAllowances = rawLpAllowances.map(lpBalance => {
    return new BigNumber(lpBalance.toString()).toJSON();
  });
  return parsedLpAllowances;
};

export const fetchVaultUserTokenBalances = async (account: string, vaultsToFetch: VaultConfig[]) => {
  const calls = vaultsToFetch.map(vault => {
    const lpContractAddress = getAddress(vault.lpAddresses);
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account]
    };
  });

  const rawTokenBalances = await multicall(erc20ABI, calls);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const parsedTokenBalances = rawTokenBalances.map(tokenBalance => {
    return new BigNumber(tokenBalance.toString()).toJSON();
  });
  return parsedTokenBalances;
};

export const fetchVaultUserStakedBalances = async (account: string, vaultsToFetch: VaultConfig[]) => {
  const vaultChefAddress = getVaultChefAddress();

  const calls = vaultsToFetch.map(vault => {
    return {
      address: vaultChefAddress,
      name: 'stakedWantTokens',
      params: [vault.pid, account]
    };
  });

  const rawStakedBalances = await multicall(vaultchefABI, calls);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const parsedStakedBalances = rawStakedBalances.map(stakedBalance => {
    return new BigNumber(stakedBalance.toString()).toJSON();
  });
  return parsedStakedBalances;
};
