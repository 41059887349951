import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { convertSharesToCake } from 'pages/Pools/helpers';
import { useCakeVaultContract } from 'utils/hooks/web3/use-contract';
import makeBatchRequest from 'utils/makeBatchRequest';

const useGetVaultSharesInfo = (lastUpdated?: number) => {
  const cakeVaultContract = useCakeVaultContract();
  const [totalShares, setTotalShares] = useState(null);
  const [totalCakeInVault, setTotalCakeInVault] = useState(null);
  const [pricePerFullShare, setPricePerFullShare] = useState(null);

  useEffect(() => {
    const getTotalShares = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const [sharePrice, shares] = await makeBatchRequest([
          cakeVaultContract.getPricePerFullShare(),
          cakeVaultContract.totalShares()
        ]);
        const sharePriceAsBigNumber = new BigNumber(sharePrice.toString());
        const totalSharesAsBigNumber = new BigNumber(shares.toString());
        const totalCakeInVaultEstimate = convertSharesToCake(totalSharesAsBigNumber, sharePriceAsBigNumber);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        setPricePerFullShare(sharePriceAsBigNumber);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        setTotalShares(totalSharesAsBigNumber);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        setTotalCakeInVault(totalCakeInVaultEstimate.cakeAsBigNumber);
      } catch (error) {
        console.error('[useGetVaultSharesInfo useEffect] error.message => ', error.message);
      }
    };
    getTotalShares();
  }, [cakeVaultContract, lastUpdated]);

  return {
    totalShares,
    totalCakeInVault,
    pricePerFullShare
  };
};

export default useGetVaultSharesInfo;
