import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PriceApiResponse, PriceApiThunk, PriceState } from 'state/types';

const initialState: PriceState = {
  isLoading: false,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  lastUpdated: null,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  data: null
};

// Thunks
export const fetchPrices = createAsyncThunk<PriceApiThunk>('prices/fetch', async () => {
  const response = await fetch('https://api.pancakeswap.info/api/v2/tokens');
  const data = (await response.json()) as PriceApiResponse;

  // Return normalized token names
  return {
    updated_at: data.updated_at,
    data: Object.keys(data.data).reduce((accum, token) => {
      return {
        ...accum,
        [token.toLowerCase()]: parseFloat(data.data[token].price)
      };
    }, {})
  };
});

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPrices.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchPrices.fulfilled, (state, action: PayloadAction<PriceApiThunk>) => {
      state.isLoading = false;
      state.lastUpdated = action.payload.updated_at;
      state.data = action.payload.data;
    });
  }
});

export default pricesSlice.reducer;
