
import BigNumber from 'bignumber.js';
import erc20 from 'config/abi/hrc20.json';
import stratABI from 'config/abi/vault_strat1.json';
import fuzzChefAbi from 'config/abi/fuzzMasterchef.json';
import piggyChefAbi from 'config/abi/piggy.json';
import artemisChefAbi from 'config/abi/artemis.json';
import openswapChefAbi from 'config/abi/openswap2.json';
import elkAbi from 'config/abi/elk.json';
import tranqAbi from 'config/abi/tranq.json';
import tranqTokenAbi from 'config/abi/tranqToken.json';
import tranqStratAbi from 'config/abi/tranqStrat.json';
import reverseChefAbi from 'config/abi/reverse.json';
import quartzChefAbi from 'config/abi/quartz.json';
import comfyChefAbi from 'config/abi/comfy.json';
import holygrailChefAbi from 'config/abi/holygrail.json';
import viperAbi from 'config/abi/viperStake.json';
import sushiMasterchefAbi from 'config/abi/sushiMasterchef.json';
import multicall from 'utils/multicall';
import { BIG_TEN } from 'utils/helpers/web3/big-numbers';
import {
  getAddress,
  getMasterChefAddress,
  getFuzzMasterChefAddress,
  getPiggyMasterChefAddress,
  getArtemisMasterChefAddress,
  getOpenswapMasterChefAddress,
  getSushiswapMasterChefAddress,
  getReverseMasterChefAddress,
  getQuartzMasterChefAddress,
  getComfyMasterChefAddress,
  getHolyGrailMasterChefAddress
} from 'utils/addressHelpers';
import { VaultConfig } from 'config/constants/types';
import { DEFAULT_TOKEN_DECIMAL } from 'config';
import ROUTERS, { RouterName } from 'config/web3/routers';
import masterchefABI from 'config/abi/masterchef.json';
import { simpleRPCProvider } from 'utils/helpers/web3/providers';
import TOKENS, { TokenSymbol } from 'config/web3/tokens';

const fetchVaults = async (farmsToFetch: VaultConfig[]) => {
  // Get all vault calls in 1 call
  const allVaultCalls = farmsToFetch.map(vaultConfig => {
    // first get the right masterchef
    let masterchefAddress = getMasterChefAddress();
    if (vaultConfig.chef === ROUTERS[RouterName.FuzzSwap].NAME) {
      masterchefAddress = getFuzzMasterChefAddress();
    } else if (vaultConfig.chef === ROUTERS[RouterName.OpenSwap].NAME) {
      masterchefAddress = getOpenswapMasterChefAddress();
    } else if (vaultConfig.chef === ROUTERS[RouterName.PiggyBank].NAME) {
      masterchefAddress = getPiggyMasterChefAddress();
    } else if (vaultConfig.chef === ROUTERS[RouterName.Artemis].NAME) {
      masterchefAddress = getArtemisMasterChefAddress();
    } else if (vaultConfig.chef === ROUTERS[RouterName.Reverse].NAME) {
      masterchefAddress = getReverseMasterChefAddress();
    } else if (vaultConfig.chef === ROUTERS[RouterName.SushiSwap].NAME) {
      masterchefAddress = getSushiswapMasterChefAddress();
    } else if (vaultConfig.chef === ROUTERS[RouterName.Quartz].NAME) {
      masterchefAddress = getQuartzMasterChefAddress();
    } else if (vaultConfig.chef === ROUTERS[RouterName.Comfy].NAME) {
      masterchefAddress = getComfyMasterChefAddress();
    } else if (vaultConfig.chef === ROUTERS[RouterName.HolyGrail].NAME) {
      masterchefAddress = getHolyGrailMasterChefAddress();
    } else if (vaultConfig.chef === ROUTERS[RouterName.ViperSwap].NAME) {
      if (vaultConfig.stakingPoolAddress) {
        masterchefAddress = getAddress(vaultConfig.stakingPoolAddress);
      }
    }
    const lpAddress = getAddress(vaultConfig.lpAddresses);
    return [
      // Balance of token in the LP contract
      {
        address: getAddress(vaultConfig.token.ADDRESSES),
        name: 'balanceOf',
        params: [lpAddress]
      },
      // Balance of quote token on LP contract
      {
        address: getAddress(vaultConfig.quoteToken.ADDRESSES),
        name: 'balanceOf',
        params: [lpAddress]
      },
      // Balance of LP tokens in the master chef contract
      {
        address: lpAddress,
        name: 'balanceOf',
        params: [masterchefAddress]
      }
    ];
  });
  const allVaultCalls1 = farmsToFetch.map(vaultConfig => {
    const lpAddress = getAddress(vaultConfig.lpAddresses);
    return [
      // Total supply of LP tokens
      {
        address: lpAddress,
        name: 'totalSupply'
      },
      // Token decimals
      {
        address: getAddress(vaultConfig.token.ADDRESSES),
        name: 'decimals'
      },
      // Quote token decimals
      {
        address: getAddress(vaultConfig.quoteToken.ADDRESSES),
        name: 'decimals'
      }
    ];
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const allVaultCallsFlat = [].concat(...allVaultCalls);
  const allVaultData = await multicall(erc20, allVaultCallsFlat);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const allVaultCallsFlat1 = [].concat(...allVaultCalls1);
  const allVaultData1 = await multicall(erc20, allVaultCallsFlat1);

  // Get all vault calls in 1 call
  const allStratCalls = farmsToFetch.map(vaultConfig => {
    // first get the right masterchef
    const stratAddress = getAddress(vaultConfig.stratAddresses);
    return [
      {
        address: stratAddress,
        name: 'wantLockedTotal',
        params: []
      },
      {
        address: stratAddress,
        name: 'paused',
        params: []
      }
    ];
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const allStratCallsFlat = [].concat(...allStratCalls);
  const allStratData = await multicall(stratABI, allStratCallsFlat);

  const data = await Promise.all(
    farmsToFetch.map(async (vaultConfig, idx) => {
      // 6 because there are 6 vault calls for each vault
      const tokenBalanceLP = allVaultData[3 * idx];
      const quoteTokenBalanceLP = allVaultData[3 * idx + 1];
      const lpTokenBalanceMC = allVaultData[3 * idx + 2];
      // break into 2 multicalls
      const lpTotalSupply = allVaultData1[3 * idx];
      const tokenDecimals = allVaultData1[3 * idx + 1];
      const quoteTokenDecimals = allVaultData1[3 * idx + 2];

      // 3 because there are 2 strat calls for each vault
      const wantLockedTotal = allStratData[2 * idx];
      const isPaused = allStratData[2 * idx + 1];

      let tranqData = {};
      // default to 18 then override for single-staking vaults
      let lpTokenDecimals = 18;
      if (vaultConfig.isSingleAsset) {
        lpTokenDecimals = vaultConfig.quoteToken.DECIMALS;
      }
      const withdrawFee = 200 / 10000;
      const lpTokenRatio = new BigNumber(wantLockedTotal.toString()).div(new BigNumber(lpTotalSupply.toString()));

      // Amount of token0 in 1 LP token
      const perc1LpOfTotal = DEFAULT_TOKEN_DECIMAL.div(new BigNumber(lpTotalSupply.toString()));
      const tokenPerLp = perc1LpOfTotal.times(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals));
      let quoteTokenPerLp = perc1LpOfTotal.times(quoteTokenBalanceLP.toString()).div(BIG_TEN.pow(quoteTokenDecimals));

      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      let tokenAmount = new BigNumber(tokenBalanceLP.toString()).div(BIG_TEN.pow(tokenDecimals.toString())).times(lpTokenRatio);
      let quoteTokenAmount = new BigNumber(quoteTokenBalanceLP.toString()).times(lpTokenRatio).div(BIG_TEN.pow(quoteTokenDecimals));
      let tokenPriceVsQuote;

      // Get USD per LP here
      const usdPerLp = 1;

      let farmPoolWeight = new BigNumber(0);
      let farmQuoteTokenAmount = new BigNumber(0);
      let farmRewardsPerBlock = new BigNumber(0);
      //
      //
      // Get Masterchef stuff here
      //
      //
      if (vaultConfig.chef === ROUTERS[RouterName.FarmersOnly].NAME) {
        // Native - FarmersOnly
        const lpTokenRatioMC = new BigNumber(lpTokenBalanceMC.toString()).div(new BigNumber(lpTotalSupply.toString()));
        const [info, totalAllocPoint, foxPerBlock] = await multicall(masterchefABI, [
          {
            address: getMasterChefAddress(),
            name: 'poolInfo',
            params: [vaultConfig.farmPid]
          },
          {
            address: getMasterChefAddress(),
            name: 'totalAllocPoint'
          },
          {
            address: getMasterChefAddress(),
            name: 'foxPerBlock'
          }
        ]);

        const allocPoint = new BigNumber(info.allocPoint._hex.toString());
        farmPoolWeight = allocPoint.div(new BigNumber(totalAllocPoint.toString()));
        farmQuoteTokenAmount = new BigNumber(lpTokenRatioMC).times(quoteTokenBalanceLP.toString()).div(BIG_TEN.pow(quoteTokenDecimals.toString()));
        farmRewardsPerBlock = new BigNumber(foxPerBlock.toString()).div(BIG_TEN.pow(18));
      }

      if (vaultConfig.chef === ROUTERS[RouterName.HolyGrail].NAME) {
        // HolyGrail
        const lpTokenRatioMC = new BigNumber(lpTokenBalanceMC.toString()).div(new BigNumber(lpTotalSupply.toString()));
        const [info, totalAllocPoint, rewardPerSecond] = await multicall(holygrailChefAbi, [
          {
            address: getHolyGrailMasterChefAddress(),
            name: 'poolInfo',
            params: [vaultConfig.farmPid]
          },
          {
            address: getHolyGrailMasterChefAddress(),
            name: 'totalAllocPoint'
          },
          {
            address: getHolyGrailMasterChefAddress(),
            name: 'hlyPerSecond',
            params: []
          }
        ]);

        const allocPoint = new BigNumber(info.allocPoint._hex.toString());
        farmPoolWeight = allocPoint.div(new BigNumber(totalAllocPoint.toString()));
        farmQuoteTokenAmount = new BigNumber(lpTokenRatioMC).times(quoteTokenBalanceLP.toString()).div(BIG_TEN.pow(quoteTokenDecimals.toString()));
        farmRewardsPerBlock = new BigNumber(rewardPerSecond.toString()).div(BIG_TEN.pow(18)).times(2);
      }

      if (vaultConfig.chef === ROUTERS[RouterName.Comfy].NAME) {
        // Comfy
        const lpTokenRatioMC = new BigNumber(lpTokenBalanceMC.toString()).div(new BigNumber(lpTotalSupply.toString()));
        const [info, totalAllocPoint, rewardPerSecond] = await multicall(comfyChefAbi, [
          {
            address: getComfyMasterChefAddress(),
            name: 'poolInfo',
            params: [vaultConfig.farmPid]
          },
          {
            address: getComfyMasterChefAddress(),
            name: 'totalAllocPoint'
          },
          {
            address: getComfyMasterChefAddress(),
            name: 'cSharePerSecond',
            params: []
          }
        ]);

        const allocPoint = new BigNumber(info.allocPoint._hex.toString());
        farmPoolWeight = allocPoint.div(new BigNumber(totalAllocPoint.toString()));
        farmQuoteTokenAmount = new BigNumber(lpTokenRatioMC).times(quoteTokenBalanceLP.toString()).div(BIG_TEN.pow(quoteTokenDecimals.toString()));
        farmRewardsPerBlock = new BigNumber(rewardPerSecond.toString()).div(BIG_TEN.pow(18)).times(2);
      }

      if (vaultConfig.chef === ROUTERS[RouterName.Quartz].NAME) {
        // Quartz
        const lpTokenRatioMC = new BigNumber(lpTokenBalanceMC.toString()).div(new BigNumber(lpTotalSupply.toString()));
        const [info, totalAllocPoint, rewardPerSecond] = await multicall(quartzChefAbi, [
          {
            address: getQuartzMasterChefAddress(),
            name: 'poolInfo',
            params: [vaultConfig.farmPid]
          },
          {
            address: getQuartzMasterChefAddress(),
            name: 'totalAllocPoint'
          },
          {
            address: getQuartzMasterChefAddress(),
            name: 'tSharePerSecond',
            params: []
          }
        ]);

        const allocPoint = new BigNumber(info.allocPoint._hex.toString());
        farmPoolWeight = allocPoint.div(new BigNumber(totalAllocPoint.toString()));
        farmQuoteTokenAmount = new BigNumber(lpTokenRatioMC).times(quoteTokenBalanceLP.toString()).div(BIG_TEN.pow(quoteTokenDecimals.toString()));
        farmRewardsPerBlock = new BigNumber(rewardPerSecond.toString()).div(BIG_TEN.pow(18)).times(2);
      }

      if (vaultConfig.chef === ROUTERS[RouterName.FuzzSwap].NAME) {
        // Fuzz.fi
        const lpTokenRatioFuzzMC = new BigNumber(lpTokenBalanceMC.toString()).div(new BigNumber(lpTotalSupply.toString()));
        const currentBlock = await simpleRPCProvider.getBlockNumber();
        const [info, totalAllocPoint, mult, fuzzPerBlock] = await multicall(fuzzChefAbi, [
          {
            address: getFuzzMasterChefAddress(),
            name: 'poolInfo',
            params: [vaultConfig.farmPid]
          },
          {
            address: getFuzzMasterChefAddress(),
            name: 'totalAllocPoint'
          },
          {
            address: getFuzzMasterChefAddress(),
            name: 'getMultiplier',
            params: [currentBlock, currentBlock + 1]
          },
          {
            address: getFuzzMasterChefAddress(),
            name: 'fuzzPerBlock',
            params: []
          }
        ]);

        const allocPoint = new BigNumber(info.allocPoint._hex.toString());
        farmPoolWeight = allocPoint.div(new BigNumber(totalAllocPoint.toString()));
        farmQuoteTokenAmount = new BigNumber(lpTokenRatioFuzzMC).times(quoteTokenBalanceLP.toString()).div(BIG_TEN.pow(quoteTokenDecimals.toString()));
        farmRewardsPerBlock = new BigNumber(fuzzPerBlock.toString()).times(mult.toString()).div(BIG_TEN.pow(18));
      }

      if (vaultConfig.chef === ROUTERS[RouterName.SushiSwap].NAME) {
        // Sushi
        const lpTokenRatioMC = new BigNumber(lpTokenBalanceMC.toString()).div(new BigNumber(lpTotalSupply.toString()));
        const [info, totalAllocPoint, sushiPerSecond] = await multicall(sushiMasterchefAbi, [
          {
            address: getSushiswapMasterChefAddress(),
            name: 'poolInfo',
            params: [vaultConfig.farmPid]
          },
          {
            address: getSushiswapMasterChefAddress(),
            name: 'totalAllocPoint'
          },
          {
            address: getSushiswapMasterChefAddress(),
            name: 'sushiPerSecond',
            params: []
          }
        ]);

        const allocPoint = new BigNumber(info.allocPoint._hex.toString());
        farmPoolWeight = allocPoint.div(new BigNumber(totalAllocPoint.toString()));
        farmQuoteTokenAmount = new BigNumber(lpTokenRatioMC).times(quoteTokenBalanceLP.toString()).div(BIG_TEN.pow(quoteTokenDecimals.toString()));
        farmRewardsPerBlock = new BigNumber(sushiPerSecond.toString()).times(2).div(BIG_TEN.pow(18));
      }

      if (vaultConfig.chef === ROUTERS[RouterName.Reverse].NAME) {
        // REVERSE
        const lpTokenRatioMC = new BigNumber(lpTokenBalanceMC.toString()).div(new BigNumber(lpTotalSupply.toString()));
        const [info, totalAllocPoint, rewardPerSecond] = await multicall(reverseChefAbi, [
          {
            address: getReverseMasterChefAddress(),
            name: 'poolInfo',
            params: [vaultConfig.farmPid]
          },
          {
            address: getReverseMasterChefAddress(),
            name: 'totalAllocPoint'
          },
          {
            address: getReverseMasterChefAddress(),
            name: 'rewardPerSecond',
            params: []
          }
        ]);

        const allocPoint = new BigNumber(info.allocPoint._hex.toString());
        farmPoolWeight = allocPoint.div(new BigNumber(totalAllocPoint.toString()));
        farmQuoteTokenAmount = new BigNumber(lpTokenRatioMC).times(quoteTokenBalanceLP.toString()).div(BIG_TEN.pow(quoteTokenDecimals.toString()));
        farmRewardsPerBlock = new BigNumber(rewardPerSecond.toString()).div(BIG_TEN.pow(18)).times(2);
      }

      if (vaultConfig.chef === ROUTERS[RouterName.OpenSwap].NAME) {
        // Openswap
        const lpTokenRatioMC = new BigNumber(lpTokenBalanceMC.toString()).div(new BigNumber(lpTotalSupply.toString()));
        const currentBlock = await simpleRPCProvider.getBlockNumber();
        const [info, totalAllocPoint, mult, oswapPerBlock] = await multicall(openswapChefAbi, [
          {
            address: getOpenswapMasterChefAddress(),
            name: 'poolInfo',
            params: [vaultConfig.farmPid]
          },
          {
            address: getOpenswapMasterChefAddress(),
            name: 'totalAllocPoint'
          },
          {
            address: getOpenswapMasterChefAddress(),
            name: 'getMultiplier',
            params: [currentBlock, currentBlock + 1]
          },
          {
            address: getOpenswapMasterChefAddress(),
            name: 'OpenSwapPerBlock',
            params: []
          }
        ]);

        const allocPoint = new BigNumber(info.allocPoint._hex.toString());
        farmPoolWeight = allocPoint.div(new BigNumber(totalAllocPoint.toString()));
        farmQuoteTokenAmount = new BigNumber(lpTokenRatioMC).times(quoteTokenBalanceLP.toString()).div(BIG_TEN.pow(quoteTokenDecimals.toString()));
        farmRewardsPerBlock = new BigNumber(oswapPerBlock.toString()).times(mult.toString()).div(BIG_TEN.pow(18));
      }

      if (vaultConfig.chef === ROUTERS[RouterName.PiggyBank].NAME) {
        // PiggyBank
        const lpTokenRatioPiggyMC = new BigNumber(lpTokenBalanceMC.toString()).div(new BigNumber(lpTotalSupply.toString()));
        const currentBlock = await simpleRPCProvider.getBlockNumber();
        const [info, totalAllocPoint, mult, coinkPerBlock] = await multicall(piggyChefAbi, [
          {
            address: getPiggyMasterChefAddress(),
            name: 'poolInfo',
            params: [vaultConfig.farmPid]
          },
          {
            address: getPiggyMasterChefAddress(),
            name: 'totalAllocPoint'
          },
          {
            address: getPiggyMasterChefAddress(),
            name: 'getMultiplier',
            params: [currentBlock, currentBlock + 1]
          },
          {
            address: getPiggyMasterChefAddress(),
            name: 'coinkPerBlock',
            params: []
          }
        ]);

        const allocPoint = new BigNumber(info.allocPoint._hex);
        farmPoolWeight = allocPoint.div(new BigNumber(totalAllocPoint));
        farmQuoteTokenAmount = new BigNumber(lpTokenRatioPiggyMC).times(quoteTokenBalanceLP.toString()).div(BIG_TEN.pow(quoteTokenDecimals.toString()));
        farmRewardsPerBlock = new BigNumber(coinkPerBlock.toString()).times(mult.toString()).div(BIG_TEN.pow(18));
      }

      if (vaultConfig.chef === ROUTERS[RouterName.Artemis].NAME) {
        // Artemis
        const lpTokenRatioArtemisMC = new BigNumber(lpTokenBalanceMC.toString()).div(new BigNumber(lpTotalSupply.toString()));
        const currentBlock = await simpleRPCProvider.getBlockNumber();
        const [info, totalAllocPoint, mult, misPerBlock] = await multicall(artemisChefAbi, [
          {
            address: getArtemisMasterChefAddress(),
            name: 'poolInfo',
            params: [vaultConfig.farmPid]
          },
          {
            address: getArtemisMasterChefAddress(),
            name: 'totalAllocPoint'
          },
          {
            address: getArtemisMasterChefAddress(),
            name: 'getMultiplier',
            params: [currentBlock, currentBlock + 1]
          },
          {
            address: getArtemisMasterChefAddress(),
            name: 'laboPerBlock',
            params: []
          }
        ]);

        const allocPoint = new BigNumber(info.allocPoint._hex.toString());
        farmPoolWeight = allocPoint.div(new BigNumber(totalAllocPoint.toString()));
        farmQuoteTokenAmount = new BigNumber(lpTokenRatioArtemisMC).times(quoteTokenBalanceLP.toString()).div(BIG_TEN.pow(quoteTokenDecimals.toString()));
        farmRewardsPerBlock = new BigNumber(misPerBlock.toString()).times(mult.toString()).div(BIG_TEN.pow(18));
      }

      if (vaultConfig.chef === ROUTERS[RouterName.Elk].NAME) {
        // Elk
        const [lpBalanceOf, rewardPerBlock] = await multicall(elkAbi, [
          {
            address: getAddress(vaultConfig.lpAddresses),
            name: 'balanceOf',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            params: [getAddress(vaultConfig.stakingPoolAddress)]
          },
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            address: getAddress(vaultConfig.stakingPoolAddress),
            name: 'rewardRate',
            params: []
          }
        ]);

        farmPoolWeight = new BigNumber(1);
        farmQuoteTokenAmount = new BigNumber(lpBalanceOf.toString()).times(quoteTokenPerLp).div(BIG_TEN.pow(quoteTokenDecimals.toString()));
        farmRewardsPerBlock = new BigNumber(rewardPerBlock.toString()).div(BIG_TEN.pow(18));
      }

      if (vaultConfig.chef === ROUTERS[RouterName.ViperSwap].NAME) {
        // Viper single stake
        const [lpBalanceOf, rewardPerBlock] = await multicall(viperAbi, [
          {
            address: getAddress(vaultConfig.lpAddresses),
            name: 'balanceOf',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            params: [getAddress(vaultConfig.stakingPoolAddress)]
          },
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            address: getAddress(vaultConfig.stakingPoolAddress),
            name: 'rewardPerBlock',
            params: []
          }
        ]);

        farmPoolWeight = new BigNumber(1);
        quoteTokenAmount = new BigNumber(wantLockedTotal.toString()).div(2).div(BIG_TEN.pow(quoteTokenDecimals));
        farmQuoteTokenAmount = new BigNumber(lpBalanceOf.toString()).div(2).div(BIG_TEN.pow(quoteTokenDecimals));
        farmRewardsPerBlock = new BigNumber(rewardPerBlock.toString()).div(BIG_TEN.pow(18));
      }

      if (vaultConfig.pid === 42 || vaultConfig.pid === 69) {
        // Tranq
        const [lpBalanceOf, rewardPerBlock] = await multicall(tranqAbi, [
          {
            address: getAddress(vaultConfig.lpAddresses),
            name: 'balanceOf',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            params: [getAddress(vaultConfig.stakingPoolAddress)]
          },
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            address: getAddress(vaultConfig.stakingPoolAddress),
            name: 'rewardSpeeds',
            params: [0]
          }
        ]);

        farmPoolWeight = new BigNumber(1);
        farmQuoteTokenAmount = new BigNumber(lpBalanceOf).times(quoteTokenPerLp).div(BIG_TEN.pow(quoteTokenDecimals));
        farmRewardsPerBlock = new BigNumber(rewardPerBlock).times(2).div(BIG_TEN.pow(18)); // times 2 because it's per second
      } else if (vaultConfig.chef === ROUTERS[RouterName.Tranquil].NAME) {
        // Tranq leveraged single-staking pools
        // Rewards per year Native = (tqTokenTotal*supplyRatePerT - debtTotal*borrowRatePerT) / 1e18 * (30*60*24*365)
        // Rewards per year Tranq  = (30*60*24*365) * farmRewardsPerBlock * [tqTokenTotal/totalSupply + debtTotal/totalBorrows]

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const tqTokenAddress = getAddress(vaultConfig.tqTokenAddress);
        const [totalSupply, totalBorrows, supplyRatePerTimestamp, borrowRatePerTimestamp, exchangeRateStored] = await multicall(tranqTokenAbi, [
          {
            address: tqTokenAddress,
            name: 'totalSupply',
            params: []
          },
          {
            address: tqTokenAddress,
            name: 'totalBorrows',
            params: []
          },
          {
            address: tqTokenAddress,
            name: 'supplyRatePerTimestamp',
            params: []
          },
          {
            address: tqTokenAddress,
            name: 'borrowRatePerTimestamp',
            params: []
          },
          {
            address: tqTokenAddress,
            name: 'exchangeRateCurrent',
            params: []
          }
        ]);

        const stratAddress = getAddress(vaultConfig.stratAddresses);
        const [wantLockedTotal, tqTokenTotal, debtTotal] = await multicall(tranqStratAbi, [
          {
            address: stratAddress,
            name: 'wantLockedTotal',
            params: []
          },
          {
            address: stratAddress,
            name: 'tqTokenTotal',
            params: []
          },
          {
            address: stratAddress,
            name: 'debtTotal',
            params: []
          }
        ]);

        farmPoolWeight = new BigNumber(1);
        farmQuoteTokenAmount = new BigNumber(totalSupply).div(BIG_TEN.pow(quoteTokenDecimals));
        farmRewardsPerBlock = new BigNumber(1).times(2).div(10); // times 2 because it's per second
        if (vaultConfig.token === TOKENS[TokenSymbol.USDT]) {
          // USDT gets 0.05
          farmRewardsPerBlock = farmRewardsPerBlock.div(2);
        } else if (vaultConfig.token === TOKENS[TokenSymbol.DAI]) {
          // DAI gets 0.03
          farmRewardsPerBlock = farmRewardsPerBlock.times(3).div(10);
        } else if (vaultConfig.token === TOKENS[TokenSymbol.BTC]) {
          // 1BTC gets 0.015
          farmRewardsPerBlock = farmRewardsPerBlock.times(15).div(100);
        }

        const totalSupplyReal = new BigNumber(totalSupply).times(exchangeRateStored).div(BIG_TEN.pow(18));
        const nativeSupplyApr = new BigNumber(tqTokenTotal).times(supplyRatePerTimestamp).times(30 * 60 * 24 * 365).div(BIG_TEN.pow(18));
        const nativeBorrowApr = new BigNumber(debtTotal).times(borrowRatePerTimestamp).times(30 * 60 * 24 * 365).div(BIG_TEN.pow(18));
        const nativeApr = nativeSupplyApr.minus(nativeBorrowApr).div(BIG_TEN.pow(quoteTokenDecimals));

        const supplyTranqPerYear = new BigNumber(tqTokenTotal).times(farmRewardsPerBlock).times(30 * 60 * 24 * 365).div(totalSupplyReal);
        const borrowTranqPerYear = new BigNumber(debtTotal).times(farmRewardsPerBlock).times(30 * 60 * 24 * 365).div(totalBorrows);
        const tranqPerYear = supplyTranqPerYear.plus(borrowTranqPerYear);

        tokenAmount = new BigNumber(wantLockedTotal).div(BIG_TEN.pow(quoteTokenDecimals)).div(2);
        quoteTokenAmount = new BigNumber(wantLockedTotal).div(BIG_TEN.pow(quoteTokenDecimals)).div(2);
        quoteTokenPerLp = BIG_TEN.pow(18 - quoteTokenDecimals).div(2);

        tranqData = {
          totalSupply: new BigNumber(totalSupplyReal).toJSON(),
          totalBorrows: new BigNumber(totalBorrows).toJSON(),
          stratWantLockedTotal: new BigNumber(wantLockedTotal).div(BIG_TEN.pow(quoteTokenDecimals)).toJSON(),
          stratSupply: new BigNumber(tqTokenTotal).toJSON(),
          stratDebt: new BigNumber(debtTotal).toJSON(),
          supplyRatePerTimestamp: new BigNumber(supplyRatePerTimestamp).toJSON(),
          borrowRatePerTimestamp: new BigNumber(borrowRatePerTimestamp).toJSON(),
          nativePerYear: nativeApr.toJSON(),
          tranqPerYear: tranqPerYear.toJSON()
        };
      }

      return {
        ...vaultConfig,
        tokenAmount: tokenAmount.toJSON(),
        quoteTokenAmount: quoteTokenAmount.toJSON(),
        lpTotalSupply: new BigNumber(lpTotalSupply.toString()).toJSON(),
        tokenPerLp: new BigNumber(tokenPerLp).toJSON(),
        quoteTokenPerLp: new BigNumber(quoteTokenPerLp).toJSON(),
        usdPerLp: new BigNumber(usdPerLp).toJSON,
        tokenPriceVsQuote: vaultConfig.pid === 0 ? tokenPriceVsQuote : quoteTokenAmount.div(tokenAmount),
        withdrawFee: new BigNumber(withdrawFee).toJSON(),
        isPaused: isPaused[0],
        farmPoolWeight: farmPoolWeight.toJSON(),
        farmQuoteTokenAmount: farmQuoteTokenAmount.toJSON(),
        farmRewardsPerBlock: farmRewardsPerBlock.toJSON(),
        lpTokenDecimals: lpTokenDecimals,
        tranq: tranqData
      };
    })
  );
  return data;
};

export default fetchVaults;
