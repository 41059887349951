
const PAGES = Object.freeze({
  HOME: '/',
  FARMS: '/farms',
  FARMS_HISTORY: '/farms/history',
  FARMS_ARCHIVED: '/farms/archived',
  POOLS: '/pools',
  POOLS_HISTORY: '/pools/history',
  VAULTS: '/vaults',
  VAULTS_HISTORY: '/vaults/history',
  VAULTS_ARCHIVED: '/vaults/archived',
  MOON_BOI: '/moon-boi',
  ZAP: '/zap',
  JEWEL_OLD: '/jewel',
  JEWEL: '/locked/jewel',
  VIPER: '/locked/viper',
  LOOT: '/locked/loot',
  MAGIC: '/locked/magic',
  LOTTO: '/lotto'
});

export {
  PAGES
};
