import BigNumber from 'bignumber.js';
import erc20 from 'config/abi/hrc20.json';
import multicall from 'utils/multicall';
import { BIG_TEN } from 'utils/helpers/web3/big-numbers';
import { getAddress } from 'utils/addressHelpers';
import { LpPricesConfig } from 'config/constants/types';

const fetchLpPrices = async (lpsToFetch: LpPricesConfig[]) => {
  const data = await Promise.all(
    lpsToFetch.map(async lpConfig => {
      const lpAddress = getAddress(lpConfig.lpAddress);
      const calls = [
        // Balance of token in the LP contract
        {
          address: getAddress(lpConfig.token.ADDRESSES),
          name: 'balanceOf',
          params: [lpAddress]
        },
        // Balance of quote token on LP contract
        {
          address: getAddress(lpConfig.quoteToken.ADDRESSES),
          name: 'balanceOf',
          params: [lpAddress]
        },
        {
          address: getAddress(lpConfig.token.ADDRESSES),
          name: 'decimals'
        },
        // Quote token decimals
        {
          address: getAddress(lpConfig.quoteToken.ADDRESSES),
          name: 'decimals'
        }
      ];

      const [
        tokenBalanceLP,
        quoteTokenBalanceLP,
        tokenDecimals,
        quoteTokenDecimals
      ] = await multicall(erc20, calls);

      const tokenAmount = new BigNumber(tokenBalanceLP.toString()).div(BIG_TEN.pow(tokenDecimals.toString()));
      const quoteTokenAmount = new BigNumber(quoteTokenBalanceLP.toString()).div(BIG_TEN.pow(quoteTokenDecimals.toString()));

      return {
        ...lpConfig,
        tokenAmount: tokenAmount.toJSON(),
        quoteTokenAmount: quoteTokenAmount.toJSON(),
        price: quoteTokenAmount.div(tokenAmount).toJSON()
      };
    })
  );
  return data;
};

export default fetchLpPrices;
