import { useEffect, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'state';
import orderBy from 'lodash/orderBy';
import TOKENS, {
  Token,
  TokenSymbol
} from 'config/web3/tokens';
import { Team } from 'config/constants/types';
import { simpleRPCProvider } from 'utils/helpers/web3/providers';
import { getAddress } from 'utils/addressHelpers';
import { getBalanceNumber } from 'utils/helpers/web3/balances';
import { BIG_TEN, BIG_ZERO } from 'utils/helpers/web3/big-numbers';
import useRefresh from 'hooks/useRefresh';
import {
  fetchFarmsPublicDataAsync,
  fetchLPsPublicDataAsync,
  fetchPoolsPublicDataAsync,
  fetchVaultsPublicDataAsync,
  fetchPoolsUserDataAsync,
  setBlock
} from './actions';
import {
  State,
  Farm,
  Pool,
  ProfileState,
  TeamsState,
  AchievementState,
  PriceState,
  FarmsState,
  Vault,
  VaultsState,
  LpPrices
} from './types';
import { fetchProfile } from './profile';
import { fetchTeam, fetchTeams } from './teams';
import { fetchAchievements } from './achievements';
import { fetchPrices } from './prices';
import useGetVaultSharesInfo from '../hooks/cakeVault/useGetVaultSharesInfo';
import useLastUpdated from '../hooks/useLastUpdated';
import useGetVaultUserInfo from '../hooks/cakeVault/useGetVaultUserInfo';

export const useFetchPublicData = () => {
  const dispatch = useAppDispatch();
  const { slowRefresh } = useRefresh();
  useEffect(() => {
    dispatch(fetchLPsPublicDataAsync());
    dispatch(fetchVaultsPublicDataAsync());
    dispatch(fetchFarmsPublicDataAsync());
    dispatch(fetchPoolsPublicDataAsync());
  }, [dispatch, slowRefresh]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const blockNumber = await simpleRPCProvider.getBlockNumber();
      dispatch(setBlock(blockNumber));
    }, 6000);

    return () => clearInterval(interval);
  }, [dispatch]);
};

export const useFetchPublicData2 = () => {
  const dispatch = useAppDispatch();
  const { slowRefresh } = useRefresh();
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync());
    dispatch(fetchLPsPublicDataAsync());
    dispatch(fetchPoolsPublicDataAsync());
  }, [dispatch, slowRefresh]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const blockNumber = await simpleRPCProvider.getBlockNumber();
      dispatch(setBlock(blockNumber));
    }, 6000);

    return () => clearInterval(interval);
  }, [dispatch]);
};

// Vaults
export const useVaults = (): VaultsState => {
  const vaults = useSelector((state: State) => state.vaults);
  return vaults;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const useVaultFromPid = (pid): Vault => {
  const vault = useSelector((state: State) => state.vaults.data.find(f => f.pid === pid));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return vault;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const useVaultUser = pid => {
  const vault = useVaultFromPid(pid);

  return {
    allowance: vault.userData ? new BigNumber(vault.userData.allowance) : BIG_ZERO,
    tokenBalance: vault.userData ? new BigNumber(vault.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: vault.userData ? new BigNumber(vault.userData.stakedBalance) : BIG_ZERO,
    earnings: vault.userData ? new BigNumber(vault.userData.earnings) : BIG_ZERO
  };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const useZapperUser = pid => {
  const vault = useVaultFromPid(pid);

  return {
    allowance: vault.userData ? new BigNumber(vault.userData.allowance) : BIG_ZERO,
    tokenBalance: vault.userData ? new BigNumber(vault.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: vault.userData ? new BigNumber(vault.userData.stakedBalance) : BIG_ZERO,
    earnings: vault.userData ? new BigNumber(vault.userData.earnings) : BIG_ZERO
  };
};

// Farms
export const useFarms = (): FarmsState => {
  const farms = useSelector((state: State) => state.farms);

  return farms;
};

export const usePrices = (): LpPrices[] => {
  return useSelector((state: State) => state.lpPrices.data);
};

export const useFarmFromPID = (pid: number): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find(item => item.pid === pid));
  if (farm === undefined) {
    throw new Error('Something went wrong!');
  }

  return farm;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const useUserVaultDataFromPid = pid => {
  const vaultData = useSelector((state: State) => state.vaults.data.find(f => f.pid === pid));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return vaultData.userProfitData;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const useLpFromName = (name): LpPrices => {
  const lp = useSelector((state: State) => state.lpPrices.data.find(f => f.name === name));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return lp;
};

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find(item => item.lpSymbol === lpSymbol));
  if (farm === undefined) {
    throw new Error('Something went wrong!');
  }

  return farm;
};

export const useFarmUser = (pid: number) => {
  const farm = useFarmFromPID(pid);

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO
  };
};

export const useLpTokenPrice = (symbol: string) => {
  const farm = useFarmFromSymbol(symbol);
  const tokenPriceInUsd = useGetApiPrice(getAddress(farm.token.ADDRESSES));

  return farm.lpTotalSupply && farm.lpTotalInQuoteToken ?
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    new BigNumber(getBalanceNumber(farm.lpTotalSupply)).div(farm.lpTotalInQuoteToken).times(tokenPriceInUsd).times(2) :
    BIG_ZERO;
};

// Pools
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account));
    }
  }, [account, dispatch, fastRefresh]);

  const pools = useSelector((state: State) => state.pools.data);
  return pools;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find(p => p.sousId === sousId));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return pool;
};

// Profile

export const useFetchProfile = () => {
  const { account } = useWeb3React();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    dispatch(fetchProfile(account));
  }, [account, dispatch]);
};

export const useProfile = () => {
  const { isInitialized, isLoading, data, hasRegistered }: ProfileState = useSelector((state: State) => state.profile);
  return { profile: data, hasProfile: isInitialized && hasRegistered, isInitialized, isLoading };
};

// Teams

export const useTeam = (id: number) => {
  const team: Team = useSelector((state: State) => state.teams.data[id]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTeam(id));
  }, [id, dispatch]);

  return team;
};

export const useTeams = () => {
  const { isInitialized, isLoading, data }: TeamsState = useSelector((state: State) => state.teams);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTeams());
  }, [dispatch]);

  return { teams: data, isInitialized, isLoading };
};

// Achievements

export const useFetchAchievements = () => {
  const { account } = useWeb3React();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (account) {
      dispatch(fetchAchievements(account));
    }
  }, [account, dispatch]);
};

export const useAchievements = () => {
  const achievements: AchievementState['data'] = useSelector((state: State) => state.achievements.data);
  return achievements;
};

// Prices
export const useFetchPriceList = () => {
  const { slowRefresh } = useRefresh();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPrices());
  }, [dispatch, slowRefresh]);
};

export const useGetApiPrices = () => {
  const prices: PriceState['data'] = useSelector((state: State) => state.prices.data);
  return prices;
};

export const useGetApiPrice = (address: string) => {
  const prices = useGetApiPrices();

  if (!prices) {
    return null;
  }

  return prices[address.toLowerCase()];
};

export const useONEPriceInUSDC = (): BigNumber => {
  const bnbBusdFarm = useFarmFromPID(3);
  return bnbBusdFarm.tokenPriceVsQuote ? new BigNumber(bnbBusdFarm.tokenPriceVsQuote) : BIG_ZERO;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const getPrice = (prices, token): BigNumber => {
  if (token === TokenSymbol.USDC || token === TokenSymbol.UST || token === TokenSymbol.USDT ||
      token === TokenSymbol.BSCBUSD || token === TokenSymbol.BUSD || token === TokenSymbol.DAI) {
    return new BigNumber(1);
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const tokenPrice = prices.find(f => f.name === token);
  if (tokenPrice) {
    if (tokenPrice.quoteToken === TOKENS[TokenSymbol.WONE]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const onePrice = prices.find(f => f.name === TokenSymbol.WONE);
      return new BigNumber(onePrice.price).times(tokenPrice.price);
    }
    return new BigNumber(tokenPrice.price);
  }
  console.log('ERROR: CANNOT FIND PRICE FOR token');
  console.log(token);
  console.log(prices);
  return new BigNumber(0);
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const convertLpTokensToUsd = (lpTokens, quoteToken, quoteTokenPerLp, prices): BigNumber => {
  // Stablecoins
  if (quoteToken === TOKENS[TokenSymbol.USDC] || quoteToken === TOKENS[TokenSymbol.UST] || quoteToken === TOKENS[TokenSymbol.USDT] ||
      quoteToken === TOKENS[TokenSymbol.BSCBUSD] || quoteToken === TOKENS[TokenSymbol.BUSD] || quoteToken === TOKENS[TokenSymbol.DAI]) {
    return new BigNumber(2).times(quoteTokenPerLp).times(lpTokens).div(BIG_TEN.pow(18));
  }
  const quoteTokenPrice = getPrice(prices, quoteToken.SYMBOL);
  return new BigNumber(2).times(quoteTokenPrice).times(quoteTokenPerLp).times(lpTokens).div(BIG_TEN.pow(18));
  console.log('ERROR: CANNOT FIND PRICE FOR QuoteToken');
  console.log(quoteToken);
  console.log(prices);
  return BIG_ZERO;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const convertQuoteTokensToUsd = (quoteTokenAmount, quoteToken: Token, prices): BigNumber => {
  // Stablecoins
  if (quoteToken === TOKENS[TokenSymbol.USDC] || quoteToken === TOKENS[TokenSymbol.UST] || quoteToken === TOKENS[TokenSymbol.USDT] ||
      quoteToken === TOKENS[TokenSymbol.BSCBUSD] || quoteToken === TOKENS[TokenSymbol.BUSD] || quoteToken === TOKENS[TokenSymbol.DAI]) {
    return new BigNumber(2).times(quoteTokenAmount);
  }
  const quoteTokenPrice = getPrice(prices, quoteToken.SYMBOL);
  if (quoteTokenPrice) {
    return new BigNumber(2).times(quoteTokenPrice).times(quoteTokenAmount);
  }
  console.log('ERROR: CANNOT FIND PRICE FOR QuoteToken');
  console.log(quoteToken);
  console.log(prices);
  return BIG_ZERO;
};

export const usePriceOne = (): BigNumber => {
  const pid = 3; // EGG-BUSD LP
  const farm = useFarmFromPID(pid);
  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO;
};

export const usePriceOneMoonUSDC = (): BigNumber => {
  const cakeBusdPrice = new BigNumber(0);
  return cakeBusdPrice;
};

export const useFOXPriceInUSDC = (): BigNumber => {
  const pid = 14; // FOX-USDC LP
  const farm = useFarmFromPID(pid);

  return farm.tokenPriceVsQuote;
};

export const usePriceFuzz = (): BigNumber => {
  const lp = useLpFromName('FUZZ');
  return lp.price ? new BigNumber(lp.price) : BIG_ZERO;
};

export const usePricePiggy = (): BigNumber => {
  const lp = useLpFromName('COINK');
  return lp.price ? new BigNumber(lp.price) : BIG_ZERO;
};

export const useBurnVaultTVL = (): BigNumber => {
  const burnVault = useVaultFromPid(0);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return new BigNumber(2).times(burnVault.quoteTokenAmount);
};

export const useTotalValue = (): BigNumber => {
  // return new BigNumber(0)
  const { data: farmsLP } = useFarms();
  const prices = usePrices();

  let value = new BigNumber(0);
  for (let i = 0; i < farmsLP.length; i++) {
    const farm = farmsLP[i];
    if (farm.lpTotalInQuoteToken) {
      const val = convertQuoteTokensToUsd(
        farm.quoteTokenAmount,
        farm.quoteToken,
        prices
      );
      if (val) {
        value = value.plus(new BigNumber(val));
      }
    }
  }
  return value;
};

export const useTotalUserValues = () => {
  // return new BigNumber(0)
  const { data: vaultsLp } = useVaults();
  const { data: farmsLp } = useFarms();
  const { lastUpdated } = useLastUpdated();
  const userInfo = useGetVaultUserInfo(lastUpdated);
  const foxPriceInUSD = useFOXPriceInUSDC();
  const { pricePerFullShare } = useGetVaultSharesInfo();
  const prices = usePrices();

  // Start with the value in the FOX vault
  const valueFoxVaultUsd =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    new BigNumber(pricePerFullShare).times(userInfo.shares).times(foxPriceInUSD).div(BIG_TEN.pow(18)).div(BIG_TEN.pow(18));
  let value = new BigNumber(0);
  let valueProfit = new BigNumber(0);
  let valueFox = valueFoxVaultUsd;
  for (let i = 0; i < vaultsLp.length; i++) {
    // NOTE: i =/= pid !!!
    let val = new BigNumber(0);
    let valProfit = new BigNumber(0);
    const vault = vaultsLp[i];
    if (vault.quoteTokenAmount) {
      val = convertLpTokensToUsd(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        vault.userData.stakedBalance,
        vault.quoteToken,
        vault.quoteTokenPerLp,
        prices
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const netDeposit = vault.userProfitData.NetDeposit;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const lpProfit = new BigNumber(vault.userData.stakedBalance).div(BIG_TEN.pow(18)).minus(netDeposit);

      if (val) {
        value = value.plus(val);
      }
      // Handle Vault profit here
      if (lpProfit.gt(0) && !vault.isSingleAsset) {
        valProfit = convertLpTokensToUsd(
          BIG_TEN.pow(18).times(lpProfit),
          vault.quoteToken,
          vault.quoteTokenPerLp,
          prices
        );
        // console.log("vault profit", i, valProfit && valProfit.toNumber())
        valueProfit = valueProfit.plus(valProfit);
      }
      // Handle total FOX calc
      if (vault.quoteToken === TOKENS[TokenSymbol.FOX]) {
        valueFox = valueFox.plus(val.div(2));
      }
    }
  }
  let valueFarms = new BigNumber(0);
  for (let i = 0; i < farmsLp.length; i++) {
    let val = new BigNumber(0);
    const farm = farmsLp[i];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (farm.userData.stakedBalance) {
      val = convertLpTokensToUsd(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        new BigNumber(farm.userData.stakedBalance).div(BIG_TEN.pow(18)),
        farm.quoteToken,
        farm.quoteTokenPerLp,
        prices
      );

      if (val.gt(0)) {
        if (farm.pid === 0) {
          val = val.div(2);
        }
        valueFarms = valueFarms.plus(val);
      }
    }
  }
  const foxAmount = valueFox.div(foxPriceInUSD);
  const totalValueUsd = value.plus(valueFoxVaultUsd);
  return {
    valueUsd: totalValueUsd.gt(1) ? totalValueUsd.toNumber() : 0,
    foxAmount: foxAmount.gt(1) ? foxAmount.toNumber() : 0,
    valueFoxVaultUsd: valueFoxVaultUsd.gt(0.1) ? valueFoxVaultUsd.toNumber() : 0,
    valueVaultsUsd: value.gt(0.1) ? value.toNumber() : 0,
    valueFarmsUsd: valueFarms.gt(0.1) ? valueFarms.toNumber() : 0,
    valueProfitUsd: valueProfit.gt(0.1) ? valueProfit.toNumber() : 0
  };
};

export const useTotalValueVault = (): BigNumber => {
  // return new BigNumber(0)
  const { data: vaultsLp } = useVaults();
  const { totalCakeInVault } = useGetVaultSharesInfo();
  const foxPriceInUSD = useFOXPriceInUSDC();
  const prices = usePrices();

  // Start with the value in the FOX vault
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  let value = new BigNumber(totalCakeInVault).times(foxPriceInUSD).div(BIG_TEN.pow(18));
  for (let i = 0; i < vaultsLp.length; i++) {
    let val;
    const vault = vaultsLp[i];
    // console.log(farm)
    if (vault.quoteTokenAmount) {
      val = convertQuoteTokensToUsd(
        vault.quoteTokenAmount,
        vault.quoteToken,
        prices
      );
      if (val) {
        value = value.plus(new BigNumber(val));
      }
    }
  }
  return value;
};

// Block
export const useBlock = () => {
  return useSelector((state: State) => state.block);
};

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock);
};

// Predictions
export const useIsHistoryPaneOpen = () => {
  return useSelector((state: State) => state.predictions.isHistoryPaneOpen);
};

export const useIsChartPaneOpen = () => {
  return useSelector((state: State) => state.predictions.isChartPaneOpen);
};

export const useGetRounds = () => {
  return useSelector((state: State) => state.predictions.rounds);
};

export const useGetSortedRounds = () => {
  const roundData = useGetRounds();
  return orderBy(Object.values(roundData), ['epoch'], ['asc']);
};

export const useGetCurrentEpoch = () => {
  return useSelector((state: State) => state.predictions.currentEpoch);
};

export const useGetIntervalBlocks = () => {
  return useSelector((state: State) => state.predictions.intervalBlocks);
};

export const useGetBufferBlocks = () => {
  return useSelector((state: State) => state.predictions.bufferBlocks);
};

export const useGetTotalIntervalBlocks = () => {
  const intervalBlocks = useGetIntervalBlocks();
  const bufferBlocks = useGetBufferBlocks();
  return intervalBlocks + bufferBlocks;
};

export const useGetRound = (id: string) => {
  const rounds = useGetRounds();
  return rounds[id];
};

export const useGetCurrentRound = () => {
  const currentEpoch = useGetCurrentEpoch();
  const rounds = useGetSortedRounds();
  return rounds.find(round => round.epoch === currentEpoch);
};

export const useGetPredictionsStatus = () => {
  return useSelector((state: State) => state.predictions.status);
};

export const useGetHistoryFilter = () => {
  return useSelector((state: State) => state.predictions.historyFilter);
};

export const useGetCurrentRoundBlockNumber = () => {
  return useSelector((state: State) => state.predictions.currentRoundStartBlockNumber);
};

export const useGetMinBetAmount = () => {
  const minBetAmount = useSelector((state: State) => state.predictions.minBetAmount);
  return useMemo(() => new BigNumber(minBetAmount), [minBetAmount]);
};

export const useGetIsFetchingHistory = () => {
  return useSelector((state: State) => state.predictions.isFetchingHistory);
};

export const useGetHistory = () => {
  return useSelector((state: State) => state.predictions.history);
};

export const useGetHistoryByAccount = (account: string) => {
  const bets = useGetHistory();
  return bets ? bets[account] : [];
};

export const useGetBetByRoundId = (account: string, roundId: string) => {
  const bets = useSelector((state: State) => state.predictions.bets);

  if (!bets[account]) {
    return null;
  }

  if (!bets[account][roundId]) {
    return null;
  }

  return bets[account][roundId];
};
