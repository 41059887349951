
import clsx from 'clsx';

const Container = ({
  className,
  ...rest
}: React.ComponentPropsWithRef<'div'>): JSX.Element => (
  <div
    className={clsx(
      'mx-auto',
      'max-w-6xl',
      'px-4',
      'sm:px-6',
      className
    )}
    {...rest} />
);

export default Container;
