import { createSlice } from '@reduxjs/toolkit';
import poolsConfig from 'config/constants/pools';
import { fetchPoolsTotalStaking } from './fetchPools';
import {
  fetchLockedTokenData,
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserStakeBalances
} from './fetchPoolsUser';
import { PoolsState, Pool } from '../types';
import TOKENS, { TokenSymbol } from 'config/web3/tokens';
import { ChainID } from 'config/web3/chains';

const initialState: PoolsState = { data: [...poolsConfig] };

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolsPublicData: (state, action) => {
      const livePoolsData: Pool[] = action.payload;
      state.data = state.data.map(pool => {
        const livePoolData = livePoolsData.find(entry => entry.sousId === pool.sousId);
        return { ...pool, ...livePoolData };
      });
    },
    setPoolsUserData: (state, action) => {
      const userData = action.payload;
      state.data = state.data.map(pool => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const userPoolData = userData.find(entry => entry.sousId === pool.sousId);
        return { ...pool, userData: userPoolData };
      });
    },
    updatePoolsUserData: (state, action) => {
      const { field, value, sousId } = action.payload;
      const index = state.data.findIndex(p => p.sousId === sousId);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } };
    }
  }
});

// Actions
export const { setPoolsPublicData, setPoolsUserData, updatePoolsUserData } = PoolsSlice.actions;

// Thunks
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const fetchPoolsPublicDataAsync = () => async dispatch => {
  const totalStakings = await fetchPoolsTotalStaking();

  const liveData = poolsConfig.map(() => {
    const totalStaking = totalStakings;
    return {
      ...totalStaking
    };
  });

  dispatch(setPoolsPublicData(liveData));
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const fetchPoolsUserDataAsync = account => async dispatch => {
  const allowances = await fetchPoolsAllowance(account);
  const stakingTokenBalances = await fetchUserBalances(account);
  const stakedBalances = await fetchUserStakeBalances(account);

  const jewelAddress = TOKENS[TokenSymbol.JEWEL].ADDRESSES[ChainID.Mainnet];
  const { data: jewelData } = await fetchLockedTokenData(account, jewelAddress);
  const viperAddress = TOKENS[TokenSymbol.VIPER].ADDRESSES[ChainID.Mainnet];
  const { data: viperData } = await fetchLockedTokenData(account, viperAddress);
  const lootAddress = TOKENS[TokenSymbol.LOOT].ADDRESSES[ChainID.Mainnet];
  const { data: lootData } = await fetchLockedTokenData(account, lootAddress);
  const magicAddress = TOKENS[TokenSymbol.MAGIC].ADDRESSES[ChainID.Mainnet];
  const { data: magicData } = await fetchLockedTokenData(account, magicAddress);

  console.log('magicData', magicData);
  const userData = poolsConfig.map(pool => ({
    sousId: pool.sousId,
    allowance: allowances,
    stakingTokenBalance: stakingTokenBalances,
    stakedBalance: stakedBalances,
    jewelData,
    viperData,
    magicData,
    lootData
  }));

  dispatch(setPoolsUserData(userData));
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const updateUserAllowance = (sousId: string, account: string) => async dispatch => {
  const allowances = await fetchPoolsAllowance(account);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  dispatch(updatePoolsUserData({ sousId, field: 'allowance', value: allowances[sousId] }));
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const updateUserBalance = (sousId: string, account: string) => async dispatch => {
  const tokenBalances = await fetchUserBalances(account);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  dispatch(updatePoolsUserData({ sousId, field: 'stakingTokenBalance', value: tokenBalances[sousId] }));
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const updateUserStakedBalance = (sousId: string, account: string) => async dispatch => {
  const stakedBalances = await fetchUserStakeBalances(account);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  dispatch(updatePoolsUserData({ sousId, field: 'stakedBalance', value: stakedBalances[sousId] }));
};

export default PoolsSlice.reducer;
