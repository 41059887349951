import { createSlice } from '@reduxjs/toolkit';
import FARM_CONFIGS from 'config/web3/farm-configs';
import { checkArchivedPID } from 'utils/helpers/farms';
import fetchFarms from './fetchFarms';
import {
  fetchFarmUserEarnings,
  fetchFarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchFarmUserStakedBalances
} from './fetchFarmUser';
import { BIG_ZERO } from 'utils/helpers/web3/big-numbers';
import { FarmsState, Farm } from '../types';

const PULL_FARM_DATA = true;

const nonArchivedFarms = FARM_CONFIGS.filter(({ pid }) => !checkArchivedPID(pid));

const noAccountFarmConfig = FARM_CONFIGS.map(item => ({
  ...item,
  tokenPriceVsQuote: BIG_ZERO,
  userData: {
    allowance: '0',
    tokenBalance: '0',
    stakedBalance: '0',
    earnings: '0'
  }
}));

const initialState: FarmsState = {
  data: noAccountFarmConfig,
  loadArchivedFarmsData: false,
  userDataLoaded: false
};

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setFarmsPublicData: (state, action) => {
      const liveFarmsData: Farm[] = action.payload;
      state.data = state.data.map(farm => {
        const liveFarmData = liveFarmsData.find(f => f.pid === farm.pid);
        return { ...farm, ...liveFarmData };
      });
    },
    setFarmUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      arrayOfUserDataObjects.forEach(userDataEl => {
        const { pid } = userDataEl;
        const index = state.data.findIndex(farm => farm.pid === pid);
        state.data[index] = { ...state.data[index], userData: userDataEl };
      });
      state.userDataLoaded = true;
    },
    setLoadArchivedFarmsData: (state, action) => {
      const loadArchivedFarmsData = action.payload;
      state.loadArchivedFarmsData = loadArchivedFarmsData;
    }
  }
});

// Actions
export const { setFarmsPublicData, setFarmUserData, setLoadArchivedFarmsData } = farmsSlice.actions;

// Thunks
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const fetchFarmsPublicDataAsync = () => async (dispatch, getState): void => {
  const fetchArchived = getState().farms.loadArchivedFarmsData;
  const farmsToFetch = fetchArchived ? FARM_CONFIGS : nonArchivedFarms;
  if (PULL_FARM_DATA) {
    const farms = await fetchFarms(farmsToFetch);
    dispatch(setFarmsPublicData(farms));
  }
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const fetchFarmUserDataAsync = (account: string) => async (dispatch, getState) => {
  const fetchArchived = getState().farms.loadArchivedFarmsData;
  const farmsToFetch = fetchArchived ? FARM_CONFIGS : nonArchivedFarms;
  const userFarmAllowances = await fetchFarmUserAllowances(account, farmsToFetch);
  const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farmsToFetch);
  const userStakedBalances = await fetchFarmUserStakedBalances(account, farmsToFetch);
  const userFarmEarnings = await fetchFarmUserEarnings(account, farmsToFetch);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const arrayOfUserDataObjects = userFarmAllowances.map((farmAllowance, index) => {
    return {
      pid: farmsToFetch[index].pid,
      allowance: userFarmAllowances[index],
      tokenBalance: userFarmTokenBalances[index],
      stakedBalance: userStakedBalances[index],
      earnings: userFarmEarnings[index]
    };
  });

  dispatch(setFarmUserData({ arrayOfUserDataObjects }));
};

export default farmsSlice.reducer;
