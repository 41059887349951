
import { PAGES } from 'utils/constants/links';

type PageMeta = {
  title: string;
  description?: string;
  image?: string;
}

const DEFAULT_META: PageMeta = {
  title: 'FarmersOnlyFi',
  description:
    'The #1 Yield Aggregator on Harmony!',
  image: 'https://s3.us-west-2.amazonaws.com/farmersonly.fi/FoxSwapLogos/foxswap-thickwhite.svg'
};

const CUSTOM_META: { [key: string]: PageMeta; } = {
  [PAGES.HOME]: {
    title: 'FarmersOnlyFi | FOX'
  },
  [PAGES.FARMS]: {
    title: 'Farms | FOX'
  },
  [PAGES.POOLS]: {
    title: 'Pools | FOX'
  },
  [PAGES.VAULTS]: {
    title: 'Vaults | FOX'
  },
  [PAGES.MOON_BOI]: {
    title: 'MoonBoi | FOX'
  }
};

export {
  DEFAULT_META,
  CUSTOM_META
};
