import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import {
  QueryClientProvider,
  QueryClient
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from './App';
import Providers from './Providers';
import history from './router-history';
import TransactionToaster from 'components/tx-toasts/TransactionToaster';
import './index.css';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <Router history={history}>
        <QueryClientProvider client={queryClient}>
          <App />
          <TransactionToaster />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Router>
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
);
