import { request, gql } from 'graphql-request';
import { GRAPH_API_PREDICTIONS } from 'config/constants/endpoints';
import { Bet, BetPosition, Market, PredictionStatus, Round, RoundData } from 'state/types';
import makeBatchRequest from 'utils/makeBatchRequest';
import { getPredictionsContract } from 'utils/helpers/web3/contract-helpers';
import {
  BetResponse,
  getRoundBaseFields,
  getBetBaseFields,
  getUserBaseFields,
  RoundResponse,
  MarketResponse
} from './queries';

const numberOrNull = (value: string) => {
  if (value === null) {
    return null;
  }

  const valueNum = Number(value);
  return Number.isNaN(valueNum) ? null : valueNum;
};

const makeFutureRoundResponse = (epoch: number, startBlock: number): RoundResponse => {
  return {
    id: epoch.toString(),
    epoch: epoch.toString(),
    startBlock: startBlock.toString(),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    failed: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    startAt: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    lockAt: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    lockBlock: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    lockPrice: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    endBlock: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    closePrice: null,
    totalBets: '0',
    totalAmount: '0',
    bearBets: '0',
    bullBets: '0',
    bearAmount: '0',
    bullAmount: '0',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    position: null,
    bets: []
  };
};

const transformBetResponse = (betResponse: BetResponse): Bet => {
  const bet = {
    id: betResponse.id,
    hash: betResponse.hash,
    amount: betResponse.amount ? parseFloat(betResponse.amount) : 0,
    position: betResponse.position === 'Bull' ? BetPosition.BULL : BetPosition.BEAR,
    claimed: betResponse.claimed,
    user: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      id: betResponse.user.id,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      address: betResponse.user.address,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      block: numberOrNull(betResponse.user.block),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      totalBets: numberOrNull(betResponse.user.totalBets),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      totalBNB: numberOrNull(betResponse.user.totalBNB)
    }
  } as Bet;

  if (betResponse.round) {
    bet.round = transformRoundResponse(betResponse.round);
  }

  return bet;
};

const transformRoundResponse = (roundResponse: RoundResponse): Round => {
  const {
    id,
    epoch,
    failed,
    startBlock,
    startAt,
    lockAt,
    lockBlock,
    lockPrice,
    endBlock,
    closePrice,
    totalBets,
    totalAmount,
    bullBets,
    bearBets,
    bearAmount,
    bullAmount,
    position,
    bets = []
  } = roundResponse;

  const getRoundPosition = (positionResponse: string) => {
    if (positionResponse === 'Bull') {
      return BetPosition.BULL;
    }

    if (positionResponse === 'Bear') {
      return BetPosition.BEAR;
    }

    return null;
  };

  return {
    id,
    failed,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    epoch: numberOrNull(epoch),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    startBlock: numberOrNull(startBlock),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    startAt: numberOrNull(startAt),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    lockAt: numberOrNull(lockAt),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    lockBlock: numberOrNull(lockBlock),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    lockPrice: lockPrice ? parseFloat(lockPrice) : null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    endBlock: numberOrNull(endBlock),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    closePrice: closePrice ? parseFloat(closePrice) : null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    totalBets: numberOrNull(totalBets),
    totalAmount: totalAmount ? parseFloat(totalAmount) : 0,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    bullBets: numberOrNull(bullBets),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    bearBets: numberOrNull(bearBets),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    bearAmount: numberOrNull(bearAmount),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    bullAmount: numberOrNull(bullAmount),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    position: getRoundPosition(position),
    bets: bets.map(transformBetResponse)
  };
};

const transformMarketResponse = (marketResponse: MarketResponse): Market => {
  return {
    id: marketResponse.id,
    paused: marketResponse.paused,
    epoch: Number(marketResponse.epoch.epoch)
  };
};

const makeRoundData = (rounds: Round[]): RoundData => {
  return rounds.reduce((accum, round) => {
    return {
      ...accum,
      [round.id]: round
    };
  }, {});
};

/**
 * Given a bet object, check if it is eligible to be claimed or refunded
 */

const getCanClaim = (bet: Bet) => {
  return !bet.claimed && (bet.position === bet.round.position || bet.round.failed === true);
};

/**
 * Returns only bets where the user has won.
 * This is necessary because the API currently cannot distinguish between an uncliamed bet that has won or lost
 */

const getUnclaimedWinningBets = (bets: Bet[]): Bet[] => {
  return bets.filter(getCanClaim);
};

/**
 * Gets static data from the contract
 */

const getStaticPredictionsData = async () => {
  const predictionsContract = getPredictionsContract();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const [currentEpoch, intervalBlocks, minBetAmount, isPaused, bufferBlocks] = await makeBatchRequest([
    predictionsContract.currentEpoch(),
    predictionsContract.intervalBlocks(),
    predictionsContract.minBetAmount(),
    predictionsContract.paused(),
    predictionsContract.bufferBlocks()
  ]);

  return {
    status: isPaused ? PredictionStatus.PAUSED : PredictionStatus.LIVE,
    currentEpoch: currentEpoch.toNumber(),
    intervalBlocks: intervalBlocks.toNumber(),
    bufferBlocks: bufferBlocks.toNumber(),
    minBetAmount: minBetAmount.toNumber()
  };
};

const getMarketData = async (): Promise<{
  rounds: Round[];
  market: Market;
}> => {
  const response = (await request(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    GRAPH_API_PREDICTIONS,
    gql`
      query getMarketData {
        rounds(first: 5, orderBy: epoch, orderDirection: desc) {
          ${getRoundBaseFields()}
        }
        market(id: 1) {
          id
          paused
          epoch {
            epoch
          }
        }
      }
    `
  )) as { rounds: RoundResponse[]; market: MarketResponse; };

  return {
    rounds: response.rounds.map(transformRoundResponse),
    market: transformMarketResponse(response.market)
  };
};

const getRound = async (id: string) => {
  const response = await request(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    GRAPH_API_PREDICTIONS,
    gql`
      query getRound($id: ID!) {
        round(id: $id) {
          ${getRoundBaseFields()}
          bets {
           ${getBetBaseFields()}
            user {
             ${getUserBaseFields()}
            }
          }
        }
      }
  `,
    { id }
  );
  return response.round;
};

type BetHistoryWhereClause = Record<string, string | number | boolean | string[]>

const getBetHistory = async (
  where: BetHistoryWhereClause = {},
  first = 1000,
  skip = 0
): Promise<BetResponse[]> => {
  const response = await request(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    GRAPH_API_PREDICTIONS,
    gql`
      query getBetHistory($first: Int!, $skip: Int!, $where: Bet_filter) {
        bets(first: $first, skip: $skip, where: $where) {
          ${getBetBaseFields()}
          round {
            ${getRoundBaseFields()}
          }
          user {
            ${getUserBaseFields()}
          } 
        }
      }
    `,
    { first, skip, where }
  );
  return response.bets;
};

const getBet = async (betId: string): Promise<BetResponse> => {
  const response = await request(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    GRAPH_API_PREDICTIONS,
    gql`
      query getBet($id: ID!) {
        bet(id: $id) {
          ${getBetBaseFields()}
          round {
            ${getRoundBaseFields()}
          }
          user {
            ${getUserBaseFields()}
          } 
        }
      }
  `,
    {
      id: betId.toLowerCase()
    }
  );
  return response.bet;
};

export {
  numberOrNull,
  makeFutureRoundResponse,
  transformBetResponse,
  transformRoundResponse,
  transformMarketResponse,
  makeRoundData,
  getCanClaim,
  getUnclaimedWinningBets,
  getStaticPredictionsData,
  getMarketData,
  getRound,
  getBetHistory,
  getBet
};
