
import { ToastContainer } from '@fox/uikit';
import useToast from 'hooks/useToast';

const ToastListener = (): JSX.Element => {
  const { toasts, remove } = useToast();

  const handleRemove = (id: string) => remove(id);

  return (
    <ToastContainer
      toasts={toasts}
      onRemove={handleRemove} />
  );
};

export default ToastListener;
