
// MEMO: inspired by pancakeswap project
import * as React from 'react';
import {
  UnsupportedChainIdError,
  useWeb3React
} from '@web3-react/core';
import { NoBscProviderError } from '@binance-chain/bsc-connector';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from '@web3-react/injected-connector';
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector
} from '@web3-react/walletconnect-connector';
import {
  ConnectorNames,
  connectorLocalStorageKey
} from '@fox/uikit';

import useToast from 'hooks/useToast';
import { connectorsByName } from 'utils/helpers/web3/web3-react';
import { setupNetwork } from 'utils/helpers/web3/wallet';
import { profileClear } from 'state/profile';
import { useAppDispatch } from 'state';

const useAuth = (): {
  login: (connectorID: ConnectorNames) => void;
  logout: () => void;
} => {
  // ray test touch <
  const dispatch = useAppDispatch();
  // ray test touch >
  const {
    activate,
    deactivate
  } = useWeb3React();
  const { toastError } = useToast();

  const login = React.useCallback((connectorID: ConnectorNames) => {
    const connector = connectorsByName[connectorID];
    if (connector) {
      activate(connector, async (error: Error) => {
        if (error instanceof UnsupportedChainIdError) {
          const hasSetup = await setupNetwork();
          if (hasSetup) {
            activate(connector);
          }
        } else {
          window.localStorage.removeItem(connectorLocalStorageKey);
          if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
            toastError('Provider Error', 'No provider was found!');
          } else if (
            error instanceof UserRejectedRequestErrorInjected ||
            error instanceof UserRejectedRequestErrorWalletConnect
          ) {
            if (connector instanceof WalletConnectConnector) {
              const walletConnector = connector as WalletConnectConnector;
              walletConnector.walletConnectProvider = null;
            }
            toastError('Authorization Error', 'Please authorize to access your account!');
          } else {
            toastError(error.name, error.message);
          }
        }
      });
    } else {
      toastError('Unable to find connector', 'The connector config is wrong!');
    }
  }, [
    activate,
    toastError
  ]);

  const logout = React.useCallback(() => {
    // ray test touch <
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    dispatch(profileClear());
    // ray test touch >
    deactivate();
    // This localStorage key is set by @web3-react/walletconnect-connector
    if (window.localStorage.getItem('walletconnect')) {
      connectorsByName.walletconnect.close();
      connectorsByName.walletconnect.walletConnectProvider = null;
    }
    window.localStorage.removeItem(connectorLocalStorageKey);
  }, [
    deactivate,
    dispatch
  ]);

  return {
    login,
    logout
  };
};

export default useAuth;
