
import { Menu as UIKitMenu, MenuEntry } from '@fox/uikit';
import { useWeb3React } from '@web3-react/core';

import { languageList } from 'config/localization/languages';
import {
  FOXSWAP_FOR_FOX_LINK,
  BSC_BRIDGE_GUIDE_LINK,
  ETH_BRIDGE_GUIDE_LINK,
  RELAY_BRIDGE_GUIDE_LINK,
  TERRA_BRIDGE_GUIDE_LINK,
  DOCS_LINK,
  MEDIUM_LINK,
  GIT_HUB_LINK,
  RUG_DOC_REVIEW_LINK,
  FOXSWAP_XFOX_LINK,
  FOX_ESCROW_LINK
} from 'config/links';
import { PAGES } from 'utils/constants/links';
import useTheme from 'hooks/useTheme';
import { useTranslation } from 'contexts/Localization';
import {
  getPrice,
  usePrices,
  useProfile
} from 'state/hooks';

const links: Array<MenuEntry> = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: PAGES.HOME
  },
  {
    label: 'FoxSwap ✨New✨',
    icon: 'TradeIcon',
    href: FOXSWAP_FOR_FOX_LINK,
    external: true
  },
  {
    label: 'Vaults',
    icon: 'VaultsIcon',
    href: PAGES.VAULTS
  },
  {
    label: 'FoxEscrow ✨New✨',
    icon: 'VaultsIcon',
    href: FOX_ESCROW_LINK
  },
  {
    label: 'Get xFOX ✨New✨',
    icon: 'IfoIcon',
    href: FOXSWAP_XFOX_LINK,
    external: true
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: PAGES.POOLS
  },
  {
    label: 'Zap',
    icon: 'PredictionsIcon',
    href: PAGES.ZAP
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: PAGES.FARMS
  },
  {
    label: 'Locked Tokens',
    icon: 'TeamBattleIcon',
    items: [
      {
        label: '💎 Jewel',
        href: PAGES.JEWEL
      },
      {
        label: '🐍 Viper',
        href: PAGES.VIPER
      },
      {
        label: '🎮 Loot',
        href: PAGES.LOOT
      },
      {
        label: '🔮 Magic',
        href: PAGES.MAGIC
      }
    ]
  },
  {
    label: 'Bridge Assets',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Relay Bridge Guide',
        href: RELAY_BRIDGE_GUIDE_LINK,
        external: true
      },
      {
        label: 'BSC Bridge Guide',
        href: BSC_BRIDGE_GUIDE_LINK,
        external: true
      },
      {
        label: 'ETH Bridge Guide',
        href: ETH_BRIDGE_GUIDE_LINK,
        external: true
      },
      {
        label: 'Terra Bridge Guide',
        href: TERRA_BRIDGE_GUIDE_LINK,
        external: true
      }
    ]
  },
  {
    label: 'About',
    icon: 'GroupsIcon',
    items: [
      {
        label: 'Docs',
        href: DOCS_LINK,
        external: true
      },
      {
        label: 'Medium',
        href: MEDIUM_LINK,
        external: true
      },
      {
        label: 'GitHub',
        href: GIT_HUB_LINK,
        external: true
      },
      {
        label: 'RugDoc Review',
        href: RUG_DOC_REVIEW_LINK,
        external: true
      }
    ]
  }
];

// TODO: should type properly
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Menu = (props: any): JSX.Element => {
  const { account } = useWeb3React();
  const {
    isDark,
    toggleTheme
  } = useTheme();
  const prices = usePrices();
  const foxPriceInUSD = getPrice(prices, 'FOX');
  const { profile } = useProfile();
  const {
    currentLanguage,
    setLanguage
  } = useTranslation();

  return (
    <UIKitMenu
      account={account}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      cakePriceUsd={foxPriceInUSD.isNaN() ? undefined : foxPriceInUSD}
      links={links}
      profile={{
        username: profile?.username,
        image: profile?.nft ? `/images/nfts/${profile.nft?.images.sm}` : undefined,
        profileLink: '/profile',
        noProfileLink: '/profile',
        showPip: !profile?.username
      }}
      {...props} />
  );
};

export default Menu;
