
import clsx from 'clsx';

const CLASS_NAMES = Object.freeze({
  LIGHT: 'light',
  DARK: 'dark'
});

const ZAP_INPUT_HEIGHT_STYLING = 'h-12';

const DIVIDE_STYLING = clsx(
  'divide-solid',
  'divide-black',
  'divide-opacity-25',
  'dark:divide-white',
  'dark:divide-opacity-25'
);

export {
  CLASS_NAMES,
  ZAP_INPUT_HEIGHT_STYLING,
  DIVIDE_STYLING
};
