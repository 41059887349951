import autofoxAbi from 'config/abi/autofox.json';
import multicall from 'utils/multicall';
import { getAutoFoxAddress } from 'utils/addressHelpers';
import BigNumber from 'bignumber.js';

// eslint-disable-next-line import/prefer-default-export
export const fetchPoolsTotalStaking = async () => {
  const calls = [
    {
      address: getAutoFoxAddress(),
      name: 'balanceOf',
      params: []
    }
  ];
  const totalStaked = await multicall(autofoxAbi, calls);

  return {
    totalStaked: new BigNumber(totalStaked.toString()).toJSON()
  };
};
