
import BigNumber from 'bignumber.js';
import erc20 from 'config/abi/hrc20.json';
import masterchefABI from 'config/abi/masterchef.json';
import multicall from 'utils/multicall';
import { BIG_TEN } from 'utils/helpers/web3/big-numbers';
import {
  getAddress,
  getMasterChefAddress
} from 'utils/addressHelpers';
import { FarmConfig } from 'config/constants/types';
import { DEFAULT_TOKEN_DECIMAL } from 'config';

const fetchFarms = async (farmsToFetch: FarmConfig[]) => {
  const data = await Promise.all(
    farmsToFetch.map(async farmConfig => {
      const lpAddress = getAddress(farmConfig.lpAddresses);
      const calls = [
        // Balance of token in the LP contract
        {
          address: getAddress(farmConfig.token.ADDRESSES),
          name: 'balanceOf',
          params: [lpAddress]
        },
        // Balance of quote token on LP contract
        {
          address: getAddress(farmConfig.quoteToken.ADDRESSES),
          name: 'balanceOf',
          params: [lpAddress]
        },
        // Balance of LP tokens in the master chef contract
        {
          address: lpAddress,
          name: 'balanceOf',
          params: [getMasterChefAddress()]
        },
        // Total supply of LP tokens
        {
          address: lpAddress,
          name: 'totalSupply'
        },
        // Token decimals
        {
          address: getAddress(farmConfig.token.ADDRESSES),
          name: 'decimals'
        },
        // Quote token decimals
        {
          address: getAddress(farmConfig.quoteToken.ADDRESSES),
          name: 'decimals'
        }
      ];

      const [
        tokenBalanceLP,
        quoteTokenBalanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals
      ] = await multicall(erc20, calls);

      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = new BigNumber(lpTokenBalanceMC.toString()).div(new BigNumber(lpTotalSupply.toString()));

      // Amount of token0 in 1 LP token
      const perc1LpOfTotal = DEFAULT_TOKEN_DECIMAL.div(new BigNumber(lpTotalSupply.toString()));
      let tokenPerLp = perc1LpOfTotal.times(tokenBalanceLP);
      let quoteTokenPerLp = perc1LpOfTotal.times(quoteTokenBalanceLP);

      // Total value in staking in quote token value
      let lpTotalInQuoteToken = new BigNumber(quoteTokenBalanceLP.toString())
        .div(DEFAULT_TOKEN_DECIMAL)
        .times(new BigNumber(2))
        .times(lpTokenRatio);

      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      let tokenAmount = new BigNumber(tokenBalanceLP.toString()).div(BIG_TEN.pow(tokenDecimals)).times(lpTokenRatio);
      let tokenPriceVsQuote = new BigNumber(0);
      let quoteTokenAmount = new BigNumber(quoteTokenBalanceLP.toString()).div(BIG_TEN.pow(quoteTokenDecimals.toString())).times(lpTokenRatio);

      // handle solo pools
      if (farmConfig.pid === 0) {
        tokenPriceVsQuote = new BigNumber(1);
        tokenPerLp = new BigNumber(1);
        quoteTokenPerLp = new BigNumber(1);
        tokenAmount = new BigNumber(lpTokenBalanceMC.toString()).div(BIG_TEN.pow(tokenDecimals.toString()));
        quoteTokenAmount = tokenAmount.div(2);
        lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote);
      }

      const [info, totalAllocPoint] = await multicall(masterchefABI, [
        {
          address: getMasterChefAddress(),
          name: 'poolInfo',
          params: [farmConfig.pid]
        },
        {
          address: getMasterChefAddress(),
          name: 'totalAllocPoint'
        }
      ]);

      const allocPoint = new BigNumber(info.allocPoint._hex.toString());
      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint.toString()));

      return {
        ...farmConfig,
        tokenAmount: tokenAmount.toJSON(),
        quoteTokenAmount: quoteTokenAmount.toJSON(),
        lpTotalSupply: new BigNumber(lpTotalSupply.toString()).toJSON(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: farmConfig.pid === 0 ? new BigNumber(1) : quoteTokenAmount.div(tokenAmount),
        poolWeight: poolWeight.toJSON(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        depositFeeBP: info.depositFeeBP,
        tokenPerLp: tokenPerLp.toJSON(),
        quoteTokenPerLp: quoteTokenPerLp.toJSON()
      };
    })
  );
  return data;
};

export default fetchFarms;
