
import * as React from 'react';
import {
  Route,
  Switch
} from 'react-router-dom';
import { withErrorBoundary } from 'react-error-boundary';
import BigNumber from 'bignumber.js';
import { ResetCSS } from '@fox/uikit';

import Menu from 'components/Menu';
import ToastListener from 'components/ToastListener';
import PageLoader from 'components/PageLoader';
import EasterEgg from 'components/EasterEgg';
import ErrorFallback from 'components/ErrorFallback';
import useEagerConnect from 'utils/hooks/web3/use-eager-connect';
import { PAGES } from 'utils/constants/links';
import { CLASS_NAMES } from 'utils/constants/styles';
import GlobalStyle from 'style/Global';
import {
  useFetchPriceList,
  useFetchProfile,
  useFetchPublicData
} from 'state/hooks';

const Home = React.lazy(() =>
  import(/* webpackChunkName: 'home' */ './pages/Home')
);
const Farms = React.lazy(() =>
  import(/* webpackChunkName: 'farms' */ './pages/Farms')
);
const Pools = React.lazy(() =>
  import(/* webpackChunkName: 'pools' */ './pages/Pools')
);
const Vaults = React.lazy(() =>
  import(/* webpackChunkName: 'vaults' */ './pages/Vaults')
);
const NotFound = React.lazy(() =>
  import(/* webpackChunkName: 'not-found' */ './pages/NotFound')
);
const MoonBoi = React.lazy(() =>
  import(/* webpackChunkName: 'moon-boi' */ './pages/MoonBoi')
);
const Zap = React.lazy(() =>
  import(/* webpackChunkName: 'zap' */ './pages/Zap')
);
const Jewel = React.lazy(() =>
  import(/* webpackChunkName: 'jewel' */ './pages/LockedTokens/Jewel')
);
const Viper = React.lazy(() =>
  import(/* webpackChunkName: 'viper' */ './pages/LockedTokens/Viper')
);
const Loot = React.lazy(() =>
  import(/* webpackChunkName: 'loot' */ './pages/LockedTokens/Loot')
);
const Magic = React.lazy(() =>
  import(/* webpackChunkName: 'magic' */ './pages/LockedTokens/Magic')
);
const Lotto = React.lazy(() =>
  import(/* webpackChunkName: 'lottery' */ './pages/Lotto')
);

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

const App = (): JSX.Element => {
  useEagerConnect();
  useFetchPublicData();
  useFetchProfile();
  useFetchPriceList();

  React.useEffect(() => {
    document.documentElement.classList.add(CLASS_NAMES.DARK);
    document.body.classList.add('text-farmersOnlyTextPrimaryInLightMode');
    document.body.classList.add('dark:text-farmersOnlyTextPrimaryInDarkMode');
  }, []);

  return (
    <>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <React.Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path={PAGES.FARMS}>
              <Farms />
            </Route>
            <Route path={PAGES.POOLS}>
              <Pools />
            </Route>
            <Route path={PAGES.VAULTS}>
              <Vaults />
            </Route>
            <Route path={PAGES.MOON_BOI}>
              <MoonBoi />
            </Route>
            <Route path={PAGES.ZAP}>
              <Zap />
            </Route>
            <Route path={PAGES.JEWEL_OLD}>
              <Jewel />
            </Route>
            <Route path={PAGES.JEWEL}>
              <Jewel />
            </Route>
            <Route path={PAGES.VIPER}>
              <Viper />
            </Route>
            <Route path={PAGES.LOOT}>
              <Loot />
            </Route>
            <Route path={PAGES.MAGIC}>
              <Magic />
            </Route>
            <Route path={PAGES.LOTTO}>
              <Lotto />
            </Route>
            <Route
              path={PAGES.HOME}
              exact>
              <Home />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </React.Suspense>
      </Menu>
      <EasterEgg iterations={2} />
      <ToastListener />
    </>
  );
};

export default withErrorBoundary(App, {
  FallbackComponent: ErrorFallback,
  onReset: () => {
    window.location.reload();
  }
});
