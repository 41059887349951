import { ChainID } from 'config/web3/chains';

const CONTRACT_ADDRESSES = Object.freeze({
  artemisMasterChef: {
    [ChainID.Testnet]: '0x59C777cd749b307Be910f15c54A3116ff88f9706',
    [ChainID.Mainnet]: '0x59C777cd749b307Be910f15c54A3116ff88f9706'
  },
  holygrailMasterChef: {
    [ChainID.Testnet]: '0xEBBDc5c850dBb0B0894FE13b0F76A7C7Ac431e78',
    [ChainID.Mainnet]: '0xEBBDc5c850dBb0B0894FE13b0F76A7C7Ac431e78'
  },
  quartzMasterChef: {
    [ChainID.Testnet]: '0x1da194F8baf85175519D92322a06b46A2638A530',
    [ChainID.Mainnet]: '0x1da194F8baf85175519D92322a06b46A2638A530'
  },
  comfyMasterChef: {
    [ChainID.Testnet]: '0x53efc025d19270b899eBf89DD89a1F58CE1CD66f',
    [ChainID.Mainnet]: '0x53efc025d19270b899eBf89DD89a1F58CE1CD66f'
  },
  autofox: {
    [ChainID.Testnet]: '0xA68E643e1942fA8635776b718F6EeD5cEF2a3F15',
    [ChainID.Mainnet]: '0xA68E643e1942fA8635776b718F6EeD5cEF2a3F15'
  },
  jewel: {
    [ChainID.Testnet]: '0x72Cb10C6bfA5624dD07Ef608027E366bd690048F',
    [ChainID.Mainnet]: '0x72Cb10C6bfA5624dD07Ef608027E366bd690048F'
  },
  masterChef: {
    [ChainID.Testnet]: '0x511Ac8266f5088bb450d954090BeB9Df78E9c745',
    [ChainID.Mainnet]: '0x15e04418d328c39ba747690f6dae9bbf548cd358'
  },
  fuzzMasterChef: {
    [ChainID.Testnet]: '0x847b46Ed6c3DF75E34a0496eF148b89Bf5eB41b1',
    [ChainID.Mainnet]: '0x847b46Ed6c3DF75E34a0496eF148b89Bf5eB41b1'
  },
  sushiswapMasterchef: {
    [ChainID.Testnet]: '0x67dA5f2FfaDDfF067AB9d5F025F8810634d84287',
    [ChainID.Mainnet]: '0x67dA5f2FfaDDfF067AB9d5F025F8810634d84287'
  },
  piggyMasterChef: {
    [ChainID.Testnet]: '0xF2a7597b1c5759D28fa6a61ee9D6D184Dc86136d',
    [ChainID.Mainnet]: '0xF2a7597b1c5759D28fa6a61ee9D6D184Dc86136d'
  },
  reverseMasterChef: {
    [ChainID.Testnet]: '0xeEA71889c062c135014Ec34825a1958c87A2Ac61',
    [ChainID.Mainnet]: '0xeEA71889c062c135014Ec34825a1958c87A2Ac61'
  },
  vaultChef: {
    [ChainID.Testnet]: '0x2914646E782Cc36297c6639734892927B3b6Fe56',
    [ChainID.Mainnet]: '0x2914646E782Cc36297c6639734892927B3b6Fe56'
  },
  barChef: {
    [ChainID.Testnet]: '0x4D22A39f8cC6584fD334D140E448D141ec67CE09',
    [ChainID.Mainnet]: '0x4D22A39f8cC6584fD334D140E448D141ec67CE09'
  },
  openswapMasterChef: {
    [ChainID.Testnet]: '0xb2E9B85FB43082F3148B0D13450E8BEB5C9B63f2',
    [ChainID.Mainnet]: '0xb2E9B85FB43082F3148B0D13450E8BEB5C9B63f2'
  },
  sousChef: {
    [ChainID.Testnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
    [ChainID.Mainnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859'
  },
  lottery: {
    [ChainID.Testnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
    [ChainID.Mainnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859'
  },
  lotteryNFT: {
    [ChainID.Testnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
    [ChainID.Mainnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859'
  },
  multiCall: {
    [ChainID.Testnet]: '0xFE4980f62D708c2A84D3929859Ea226340759320',
    [ChainID.Mainnet]: '0xFE4980f62D708c2A84D3929859Ea226340759320'
  },
  pancakeProfile: {
    [ChainID.Testnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
    [ChainID.Mainnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859'
  },
  pancakeRabbits: {
    [ChainID.Testnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
    [ChainID.Mainnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859'
  },
  bunnyFactory: {
    [ChainID.Testnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
    [ChainID.Mainnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859'
  },
  claimRefund: {
    [ChainID.Testnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
    [ChainID.Mainnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859'
  },
  pointCenterIfo: {
    [ChainID.Testnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
    [ChainID.Mainnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859'
  },
  bunnySpecial: {
    [ChainID.Testnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
    [ChainID.Mainnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859'
  },
  tradingCompetition: {
    [ChainID.Testnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
    [ChainID.Mainnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859'
  },
  tranqComprollerAddress: {
    [ChainID.Testnet]: '0x6a82A17B48EF6be278BBC56138F35d04594587E3',
    [ChainID.Mainnet]: '0x6a82A17B48EF6be278BBC56138F35d04594587E3'
  },
  easterNft: {
    [ChainID.Testnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
    [ChainID.Mainnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859'
  },
  cakeVault: {
    [ChainID.Testnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
    [ChainID.Mainnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859'
  },
  predictions: {
    [ChainID.Testnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
    [ChainID.Mainnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859'
  },
  chainlinkOracle: {
    [ChainID.Testnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859',
    [ChainID.Mainnet]: '0xAB466B0656725bE5B0fD950668884E6A893bd859'
  },
  ZAPPER: {
    [ChainID.Testnet]: '0xF0311506D4701418a0DB84A75FBb33bad9f87439',
    [ChainID.Mainnet]: '0xF0311506D4701418a0DB84A75FBb33bad9f87439'
  }
});

export default CONTRACT_ADDRESSES;
