import { Campaign } from 'config/constants/types';
import { getPointCenterIfoContract } from 'utils/helpers/web3/contract-helpers';
import ifosList from 'config/constants/ifo';
import { campaignMap } from 'config/constants/campaigns';
import { Achievement, TranslatableText } from 'state/types';
import makeBatchRequest from './makeBatchRequest';

// TODO: not used for now
// interface IfoMapResponse {
//   thresholdToClaim: string;
//   campaignId: string;
//   numberPoints: string;
// }

export const getAchievementTitle = (campaign: Campaign): TranslatableText => {
  switch (campaign.type) {
  case 'ifo':
    return {
      key: 'IFO Shopper: %title%',
      data: {
        title: campaign.title as string
      }
    };
  default:
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return campaign.title;
  }
};

export const getAchievementDescription = (campaign: Campaign): TranslatableText => {
  switch (campaign.type) {
  case 'ifo':
    return {
      key: 'Committed more than $5 worth of LP in the %title% IFO',
      data: {
        title: campaign.title as string
      }
    };
  default:
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return campaign.description;
  }
};

/**
 * Checks if a wallet is eligible to claim points from valid IFO's
 */

export const getClaimableIfoData = async (account: string): Promise<Achievement[]> => {
  const ifoCampaigns = ifosList.filter(ifoItem => ifoItem.campaignId !== undefined);
  const ifoCampaignAddresses = ifoCampaigns.map(ifoItem => ifoItem.address);
  const pointCenterContract = getPointCenterIfoContract();

  // Returns the claim status of every IFO with a campaign ID
  const claimStatuses = (await pointCenterContract
    .checkClaimStatuses(account, ifoCampaignAddresses)) as boolean[];

  // Get IFO data for all IFO's that are eligible to claim
  const claimableIfoData = (await makeBatchRequest(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    claimStatuses.reduce((accum, claimStatus, index) => {
      if (claimStatus === true) {
        return [...accum, pointCenterContract.ifos(ifoCampaignAddresses[index])];
      }

      return accum;
    }, [])
  ));

  // Transform response to an Achievement
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return claimableIfoData.reduce((accum, claimableIfoDataItem) => {
    if (!campaignMap.has(claimableIfoDataItem.campaignId)) {
      return accum;
    }

    const campaignMeta = campaignMap.get(claimableIfoDataItem.campaignId);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const { address } = ifoCampaigns.find(ifoCampaign => ifoCampaign.campaignId === claimableIfoDataItem.campaignId);

    return [
      ...accum,
      {
        address,
        id: claimableIfoDataItem.campaignId,
        type: 'ifo',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        title: getAchievementTitle(campaignMeta),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        description: getAchievementDescription(campaignMeta),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        badge: campaignMeta.badge,
        points: claimableIfoDataItem.numberPoints.toNumber()
      }
    ];
  }, []);
};
