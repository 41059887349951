
import clsx from 'clsx';

import Page from 'components/layout/Page';
import FarmersOnlyPicture from 'components/UI/FarmersOnlyPicture';

const PageLoader = (): JSX.Element => {
  return (
    <Page
      className={clsx(
        'flex',
        'items-center',
        'justify-center'
      )}>
      <FarmersOnlyPicture
        width={207}
        height={200}
        images={[
          {
            type: 'image/png',
            path: '/assets/images/fox-logo/fox-logo.png'
          }
        ]} />
    </Page>
  );
};

export default PageLoader;
