import BigNumber from 'bignumber.js';
import { getBalanceNumber, getFullDisplayBalance, getDecimalAmount } from 'utils/helpers/web3/balances';

export const convertSharesToCake = (
  shares: BigNumber,
  cakePerFullShare: BigNumber,
  decimals = 18,
  decimalsToRound = 3
) => {
  const sharePriceNumber = getBalanceNumber(cakePerFullShare, decimals);
  const amountInCake = new BigNumber(shares.multipliedBy(sharePriceNumber));
  const cakeAsNumberBalance = getBalanceNumber(amountInCake, decimals);
  const cakeAsBigNumber = getDecimalAmount(new BigNumber(cakeAsNumberBalance), decimals);
  const cakeAsDisplayBalance = getFullDisplayBalance(amountInCake, decimals, decimalsToRound);
  return { cakeAsNumberBalance, cakeAsBigNumber, cakeAsDisplayBalance };
};

export const convertCakeToShares = (
  cake: BigNumber,
  cakePerFullShare: BigNumber,
  decimals = 18,
  decimalsToRound = 3
) => {
  const sharePriceNumber = getBalanceNumber(cakePerFullShare, decimals);
  const amountInShares = new BigNumber(cake.dividedBy(sharePriceNumber));
  const sharesAsNumberBalance = getBalanceNumber(amountInShares, decimals);
  const sharesAsBigNumber = getDecimalAmount(new BigNumber(sharesAsNumberBalance), decimals);
  const sharesAsDisplayBalance = getFullDisplayBalance(amountInShares, decimals, decimalsToRound);
  return { sharesAsNumberBalance, sharesAsBigNumber, sharesAsDisplayBalance };
};
