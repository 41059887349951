import React, { useState } from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

import {
  light as lightBase,
  dark as darkBase,
  PancakeTheme
} from '@fox/uikit';

// customize light theme
const light: PancakeTheme = lightBase;
light.colors.text = '';
light.colors.text = '#121415';
light.colors.textSubtle = '#663399';
light.colors.primary = '#8B74BD';
light.colors.secondary = '#4066E0';
light.colors.warning = '#663399';
light.colors.background = '#B9BFFF';
light.colors.backgroundAlt = '#B9BFFF';

// customize dark theme
const dark: PancakeTheme = darkBase;
dark.colors.text = '#fefdfd';
dark.colors.textSubtle = '#B9BFFF';
dark.colors.primary = '#8B74BD';
dark.colors.secondary = '#4066E0';
dark.colors.warning = '#663399';
dark.colors.background = '#121415';
dark.colors.backgroundAlt = '#121415';

const CACHE_KEY = 'IS_DARK';

const ThemeContext = React.createContext({ isDark: null, toggleTheme: () => null });

const ThemeContextProvider = ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  children
}): JSX.Element => {
  const [isDark, setIsDark] = useState(() => {
    const isDarkUserSetting = localStorage.getItem(CACHE_KEY);
    return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : true;
  });

  const toggleTheme = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setIsDark(prevState => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState));
      return !prevState;
    });
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      <SCThemeProvider theme={isDark ? dark : light}>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeContextProvider };
