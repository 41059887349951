
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import clsx from 'clsx';

import Container from './Container';
import {
  CUSTOM_META,
  DEFAULT_META
} from 'config/meta';
import { useFOXPriceInUSDC } from 'state/hooks';

const StyledPage = styled(Container)`
  min-height: calc(100vh - 64px);
`;

const PageMeta = () => {
  const { pathname } = useLocation();
  const foxPriceInUSD = useFOXPriceInUSDC();
  const cakePriceUsdDisplay = foxPriceInUSD.eq(0) ?
    '' :
    `$${foxPriceInUSD.toNumber().toLocaleString(undefined, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3
    })}`;
  const pageMeta = CUSTOM_META[pathname] || {};
  const {
    title,
    description,
    image
  } = {
    ...DEFAULT_META,
    ...pageMeta
  };
  const pageTitle = cakePriceUsdDisplay ? [title, cakePriceUsdDisplay].join('') : title;

  return (
    <Helmet>
      <title>{pageTitle} - FOX</title>
      <meta
        property='og:title'
        content={title} />
      <meta
        property='og:description'
        content={description} />
      <meta
        property='og:image'
        content={image} />
    </Helmet>
  );
};

const Page = ({
  className,
  children,
  ...rest
}: React.ComponentPropsWithRef<'div'>): JSX.Element => {
  return (
    <>
      <PageMeta />
      <StyledPage
        className={clsx(
          'py-4',
          'sm:py-6',
          'lg:py-8',
          className
        )}
        {...rest}>
        {children}
      </StyledPage>
    </>
  );
};

export default Page;
