
// MEMO: inspired by pancakeswap project
import * as React from 'react';
import {
  connectorLocalStorageKey,
  ConnectorNames
} from '@fox/uikit';

import useAuth from 'utils/hooks/web3/use-auth';

const _binanceChainListener = async () =>
  new Promise<void>(resolve =>
    Object.defineProperty(window, 'BinanceChain', {
      get() {
        return this.bsc;
      },
      set(bsc) {
        this.bsc = bsc;

        resolve();
      }
    })
  );

const useEagerConnect = (): void => {
  const { login } = useAuth();

  React.useEffect(() => {
    const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames;

    if (connectorId) {
      const isConnectorBinanceChain = connectorId === ConnectorNames.BSC;
      const isBinanceChainDefined = Reflect.has(window, 'BinanceChain');

      // Currently BSC extension doesn't always inject in time.
      // We must check to see if it exists, and if not, wait for it before proceeding.
      if (isConnectorBinanceChain && !isBinanceChainDefined) {
        _binanceChainListener().then(() => login(connectorId));

        return;
      }

      login(connectorId);
    }
  }, [login]);
};

export default useEagerConnect;
