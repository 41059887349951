import { createSlice } from '@reduxjs/toolkit';
import lpPricesConfig from 'config/constants/lpPrices';
import fetchLpPrices from './fetchLpPrices';

import { LpPrices, LpPricesState } from '../types';

const noAccountFarmConfig = lpPricesConfig.map(lp => ({
  ...lp
}));

const initialState: LpPricesState = {
  data: noAccountFarmConfig
};

export const lpSlice = createSlice({
  name: 'LpPrices',
  initialState,
  reducers: {
    setLpPublicData: (state, action) => {
      const liveFarmsData: LpPrices[] = action.payload;
      state.data = state.data.map(farm => {
        const liveFarmData = liveFarmsData.find(f => f.name === farm.name);
        return { ...farm, ...liveFarmData };
      });
    }
  }
});

// Actions
export const { setLpPublicData } = lpSlice.actions;

// Thunks
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const fetchLPsPublicDataAsync = () => async dispatch => {
  const lps = await fetchLpPrices(lpPricesConfig);
  dispatch(setLpPublicData(lps));
};

export default lpSlice.reducer;
