
import multicall from 'utils/multicall';
// eslint-disable-next-line import/named
import { getFoxAddress, getAutoFoxAddress } from 'utils/addressHelpers';
import autofoxAbi from 'config/abi/autofox.json';
import jewelAbi from 'config/abi/jewel.json';
import erc20ABI from 'config/abi/hrc20.json';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const fetchPoolsAllowance = async account => {
  const calls = [
    {
      address: getFoxAddress(),
      name: 'allowance',
      params: [account, getAutoFoxAddress()]
    }
  ];

  const allowances = await multicall(erc20ABI, calls);
  return {
    allowance: allowances
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchLockedTokenData = async (account: any, address: string) => {
  const calls = [
    {
      address: address,
      name: 'balanceOf',
      params: [account]
    },
    {
      address: address,
      name: 'lockOf',
      params: [account]
    }
  ];
  const callData = await multicall(jewelAbi, calls);
  // console.log('fetchLockedTokenData', callData);
  return {
    data: callData
  };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const fetchUserBalances = async account => {
  const calls = [
    {
      address: getAutoFoxAddress(),
      name: 'wantLockedTotal',
      params: [account]
    }
  ];
  const tokenBalancesRaw = await multicall(autofoxAbi, calls);

  return { tokenBalancesRaw };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const fetchUserStakeBalances = async account => {
  const calls = [
    {
      address: getFoxAddress(),
      name: 'balanceOf',
      params: [account]
    }
  ];
  const tokenBalancesRaw = await multicall(erc20ABI, calls);

  return { tokenBalancesRaw };
};
