import { createSlice } from '@reduxjs/toolkit';
import VAULT_CONFIGS from 'config/web3/vault-configs';
import { checkArchivedPID } from 'utils/helpers/farms';
import fetchVaults from './fetchVaults';
import {
  fetchVaultUserAllowances,
  fetchVaultUserStakedBalances,
  fetchVaultUserTokenBalances
} from './fetchVaultUser';
import { VaultsState, Vault } from '../types';
import { UserVaultData } from 'config/constants/types';
import { BIG_ZERO } from 'utils/helpers/web3/big-numbers';

const baseUrl = 'https://api.elision.farm/getuservaultdata?address=';
const nonArchivedFarms = VAULT_CONFIGS.filter(({ pid }) => !checkArchivedPID(pid));

const noAccountVaultConfig = VAULT_CONFIGS.map(item => ({
  ...item,
  tokenPriceVsQuote: BIG_ZERO,
  farmPoolWeight: BIG_ZERO,
  tranq: {},
  userData: {
    allowance: '0',
    tokenBalance: '0',
    stakedBalance: '0',
    earnings: '0'
  },
  userProfitData: {
    totalDeposit: 0,
    totalWithdraw: 0,
    NetDeposit: 0
  }
}));

const initialState: VaultsState = {
  data: noAccountVaultConfig,
  loadArchivedVaultsData: false,
  userDataLoaded: false,
  userProfitDataLoaded: false
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const fetchVaultUserProfits = async account => {
  try {
    const response = await fetch(`${baseUrl}${account}`);
    const responseData: UserVaultData[] = await response.json();

    return responseData;
  } catch (error) {
    console.error('Unable to fetch data for account:', account, error);
  }
};

export const vaultsSlice = createSlice({
  name: 'Vaults',
  initialState,
  reducers: {
    setVaultsPublicData: (state, action) => {
      const liveVaultsData: Vault[] = action.payload;
      state.data = state.data.map(vault => {
        const liveVaultData = liveVaultsData.find(f => f.pid === vault.pid);
        return { ...vault, ...liveVaultData };
      });
    },
    setVaultUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      arrayOfUserDataObjects.forEach(userDataEl => {
        const { pid } = userDataEl;
        const index = state.data.findIndex(vault => vault.pid === pid);
        state.data[index] = { ...state.data[index], userData: userDataEl };
      });
      state.userDataLoaded = true;
    },
    setVaultUserProfitData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      arrayOfUserDataObjects.forEach(userDataEl => {
        const { pid } = userDataEl;
        const index = state.data.findIndex(vault => vault.pid === pid);
        state.data[index] = { ...state.data[index], userProfitData: userDataEl };
      });
      state.userProfitDataLoaded = true;
    }
  }
});

// Actions
export const {
  setVaultsPublicData,
  setVaultUserData,
  setVaultUserProfitData
} = vaultsSlice.actions;

// Thunks
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const fetchVaultsPublicDataAsync = () => async (dispatch, getState) => {
  const fetchArchived = getState().vaults.loadArchivedVaultsData;
  const vaultsToFetch = fetchArchived ? VAULT_CONFIGS : nonArchivedFarms;
  const vaults = await fetchVaults(vaultsToFetch);
  dispatch(setVaultsPublicData(vaults));
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const fetchVaultUserDataAsync = (account: string) => async (dispatch, getState) => {
  const fetchArchived = getState().vaults.loadArchivedVaultsData;
  const vaultsToFetch = fetchArchived ? VAULT_CONFIGS : nonArchivedFarms;
  const userVaultAllowances = await fetchVaultUserAllowances(account, vaultsToFetch);
  const userVaultTokenBalances = await fetchVaultUserTokenBalances(account, vaultsToFetch);
  const userStakedBalances = await fetchVaultUserStakedBalances(account, vaultsToFetch);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const arrayOfUserDataObjects = userVaultAllowances.map((vault, index) => {
    return {
      pid: vaultsToFetch[index].pid,
      allowance: userVaultAllowances[index],
      tokenBalance: userVaultTokenBalances[index],
      stakedBalance: userStakedBalances[index]
    };
  });

  dispatch(setVaultUserData({ arrayOfUserDataObjects }));
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const fetchVaultUserProfitDataAsync = (account: string) => async (dispatch, getState) => {
  const isDataLoaded = getState().vaults.userProfitDataLoaded;
  if (isDataLoaded) {
    // only load once per session
    return;
  }
  const profits = await fetchVaultUserProfits(account);
  console.log('fetchVaultUserProfitDataAsync', profits);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const arrayOfUserDataObjects = profits.map(vaultProfit => {
    return {
      pid: vaultProfit.pid,
      totalDeposit: vaultProfit.total_deposits,
      totalWithdraw: vaultProfit.total_withdraw,
      NetDeposit: vaultProfit.net_deposit
    };
  });

  dispatch(setVaultUserProfitData({ arrayOfUserDataObjects }));
};

export default vaultsSlice.reducer;
