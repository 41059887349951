
const SUSHI_SWAP_FOR_FOX_LINK =
  'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x0159ed2e06ddcd46a25e74eb8e159ce666b28687';
const FOXSWAP_FOR_FOX_LINK =
    'https://foxswap.one/#/swap?inputCurrency=ONE&outputCurrency=0x0159ed2e06ddcd46a25e74eb8e159ce666b28687';
const FOXSWAP_XFOX_LINK = 'https://foxswap.one/#/stake';
const BSC_BRIDGE_GUIDE_LINK = 'https://docs.harmony.one/home/general/horizon-bridge/bridging-bsc-one';
const ETH_BRIDGE_GUIDE_LINK = 'https://docs.harmony.one/home/general/horizon-bridge/bridging-eth-one';
const RELAY_BRIDGE_GUIDE_LINK = 'https://app.relaychain.com/#/cross-chain-bridge-transfer';
const TERRA_BRIDGE_GUIDE_LINK = 'https://docs.harmony.one/home/general/ecosystem/terra';
const DOCS_LINK = 'https://docs.farmersonly.fi';
const MEDIUM_LINK = 'https://farmersonly.medium.com';
const GIT_HUB_LINK = 'https://github.com/FarmersOnlyFi';
const RUG_DOC_REVIEW_LINK = 'https://rugdoc.io/project/farmersonly';
const REQUEST_NEW_LP_TOKENS_TO_ZAP_OUT_DISCORD_LINK = 'https://discord.gg/fcqK4jJeZh';
const SUSHI_SWAP_BUY_LINK = 'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=';
const VIPER_SWAP_BUY_LINK = 'https://viper.exchange/#/swap?inputCurrency=ETH&outputCurrency=';
const FOX_ESCROW_LINK = 'https://foxmarket.xyz/';

export {
  SUSHI_SWAP_FOR_FOX_LINK,
  VIPER_SWAP_BUY_LINK,
  FOXSWAP_XFOX_LINK,
  FOXSWAP_FOR_FOX_LINK,
  BSC_BRIDGE_GUIDE_LINK,
  ETH_BRIDGE_GUIDE_LINK,
  RELAY_BRIDGE_GUIDE_LINK,
  TERRA_BRIDGE_GUIDE_LINK,
  DOCS_LINK,
  MEDIUM_LINK,
  GIT_HUB_LINK,
  RUG_DOC_REVIEW_LINK,
  REQUEST_NEW_LP_TOKENS_TO_ZAP_OUT_DISCORD_LINK,
  SUSHI_SWAP_BUY_LINK,
  FOX_ESCROW_LINK
};
