import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { BIG_ZERO } from 'utils/helpers/web3/big-numbers';
import { useCakeVaultContract, useCake } from 'utils/hooks/web3/use-contract';

const useGetVaultUserInfo = (lastUpdated?: number) => {
  const { account } = useWeb3React();
  const cakeVaultContract = useCakeVaultContract();
  const cakeContract = useCake();

  const [userInfo, setUserInfo] = useState({
    shares: BIG_ZERO,
    crystalAtLastUserAction: BIG_ZERO,
    lastDepositedTime: '',
    lastUserActionTime: '',
    stakingTokenBalance: BIG_ZERO
  });

  useEffect(() => {
    //   user-specific vault contract fetches
    const fetchUserVaultInfo = async () => {
      const userContractInfo = await cakeVaultContract.userInfo(account);
      const userStakingTokenBalance = await cakeContract.balanceOf(account);
      setUserInfo({
        shares: new BigNumber(userContractInfo.shares.toString()),
        crystalAtLastUserAction: new BigNumber(userContractInfo.crystalAtLastUserAction.toString()),
        lastDepositedTime: userContractInfo.lastDepositedTime,
        lastUserActionTime: userContractInfo.lastUserActionTime,
        stakingTokenBalance: userStakingTokenBalance
      });
    };

    if (account) {
      fetchUserVaultInfo();
    }
  }, [account, cakeVaultContract, cakeContract, lastUpdated]);

  return userInfo;
};

export default useGetVaultUserInfo;
