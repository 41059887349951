
import * as React from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { ModalProvider } from '@fox/uikit';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import { getLibrary } from 'utils/helpers/web3/web3-react';
import { ThemeContextProvider } from 'contexts/ThemeContext';
import { LanguageProvider } from 'contexts/Localization';
import { RefreshContextProvider } from 'contexts/RefreshContext';
import { ToastsProvider } from 'contexts/ToastsContext';
import store from 'state';

interface Props {
  children: React.ReactNode;
}

const Providers = ({ children }: Props): JSX.Element => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <ToastsProvider>
          <HelmetProvider>
            <ThemeContextProvider>
              <LanguageProvider>
                <RefreshContextProvider>
                  <ModalProvider>{children}</ModalProvider>
                </RefreshContextProvider>
              </LanguageProvider>
            </ThemeContextProvider>
          </HelmetProvider>
        </ToastsProvider>
      </Provider>
    </Web3ReactProvider>
  );
};

export default Providers;
