
import FarmersOnlyImage, { Props as FarmersOnlyImageProps } from 'components/UI/FarmersOnlyImage';

interface CustomProps {
  images:
    Array<{
      type: string;
      path: string;
    }>;
}

const FarmersOnlyPicture = ({
  images,
  ...rest
}: CustomProps & Omit<FarmersOnlyImageProps, 'src'>): JSX.Element => (
  <picture>
    {images.map(image => (
      <source
        key={image.path}
        type={image.type}
        srcSet={image.path} />
    ))}
    <FarmersOnlyImage
      src={images[images.length - 1].path}
      {...rest} />
  </picture>
);

export default FarmersOnlyPicture;
