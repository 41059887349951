import BigNumber from 'bignumber.js';
import erc20ABI from 'config/abi/hrc20.json';
import masterchefABI from 'config/abi/masterchef.json';
import multicall from 'utils/multicall';
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers';
import { FarmConfig } from 'config/constants/types';

export const fetchFarmUserAllowances = async (account: string, farmsToFetch: FarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress();

  const calls = farmsToFetch.map(farm => {
    const lpContractAddress = getAddress(farm.lpAddresses);
    return { address: lpContractAddress, name: 'allowance', params: [account, masterChefAddress] };
  });

  const rawLpAllowances = await multicall(erc20ABI, calls);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const parsedLpAllowances = rawLpAllowances.map(lpBalance => {
    return new BigNumber(lpBalance.toString()).toJSON();
  });
  return parsedLpAllowances;
};

export const fetchFarmUserTokenBalances = async (account: string, farmsToFetch: FarmConfig[]) => {
  const calls = farmsToFetch.map(farm => {
    const lpContractAddress = getAddress(farm.lpAddresses);
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account]
    };
  });

  const rawTokenBalances = await multicall(erc20ABI, calls);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const parsedTokenBalances = rawTokenBalances.map(tokenBalance => {
    return new BigNumber(tokenBalance.toString()).toJSON();
  });
  return parsedTokenBalances;
};

export const fetchFarmUserStakedBalances = async (account: string, farmsToFetch: FarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress();

  const calls = farmsToFetch.map(farm => {
    return {
      address: masterChefAddress,
      name: 'userInfo',
      params: [farm.pid, account]
    };
  });

  const rawStakedBalances = await multicall(masterchefABI, calls);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const parsedStakedBalances = rawStakedBalances.map(stakedBalance => {
    return new BigNumber(stakedBalance[0]._hex.toString()).toJSON();
  });
  return parsedStakedBalances;
};

export const fetchFarmUserEarnings = async (account: string, farmsToFetch: FarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress();

  const calls = farmsToFetch.map(farm => {
    return {
      address: masterChefAddress,
      name: 'pendingFox',
      params: [farm.pid, account]
    };
  });

  const rawEarnings = await multicall(masterchefABI, calls);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const parsedEarnings = rawEarnings.map(earnings => {
    return new BigNumber(earnings.toString()).toJSON();
  });
  return parsedEarnings;
};
