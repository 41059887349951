
// MEMO: inspired by pancakeswap project
import { Signer } from '@ethersproject/abstract-signer';
import { Provider } from '@ethersproject/abstract-provider';
import { Contract } from '@ethersproject/contracts';

import { simpleRPCProvider } from 'utils/helpers/web3/providers';
import { poolsConfig } from 'config/constants';
import { PoolCategory } from 'config/constants/types';

// Addresses
import {
  getAddress,
  getPancakeProfileAddress,
  getPancakeRabbitsAddress,
  getBunnyFactoryAddress,
  getBunnySpecialAddress,
  getCakeAddress,
  getLotteryAddress,
  getBarAddress,
  getLotteryTicketAddress,
  getMasterChefAddress,
  getVaultChefAddress,
  getPointCenterIfoAddress,
  getClaimRefundAddress,
  getTradingCompetitionAddress,
  getEasterNftAddress,
  getZapperContractAddress,
  getPredictionsAddress,
  getChainlinkOracleAddress,
  getAutoFoxAddress,
  getMulticallAddress
} from 'utils/addressHelpers';

// ABI
import profileABI from 'config/abi/pancakeProfile.json';
import pancakeRabbitsAbi from 'config/abi/pancakeRabbits.json';
import bunnyFactoryAbi from 'config/abi/bunnyFactory.json';
import bunnySpecialAbi from 'config/abi/bunnySpecial.json';
import hrc20ABI from 'config/abi/hrc20.json';
import erc721Abi from 'config/abi/erc721.json';
import cakeAbi from 'config/abi/cake.json';
import ifoV1Abi from 'config/abi/ifoV1.json';
import ifoV2Abi from 'config/abi/ifoV2.json';
import pointCenterIfo from 'config/abi/pointCenterIfo.json';
import lotteryAbi from 'config/abi/lottery.json';
import barAbi from 'config/abi/mochiBar.json';
import hMochiAbi from 'config/abi/hMochi.json';
import lotteryTicketAbi from 'config/abi/lotteryNft.json';
import masterChef from 'config/abi/masterchef.json';
import vaultChef from 'config/abi/vaultChef.json';
import sousChef from 'config/abi/sousChef.json';
import sousChefV2 from 'config/abi/sousChefV2.json';
import sousChefBnb from 'config/abi/sousChefBnb.json';
import claimRefundAbi from 'config/abi/claimRefund.json';
import tradingCompetitionAbi from 'config/abi/tradingCompetition.json';
import easterNftAbi from 'config/abi/easterNft.json';
import predictionsAbi from 'config/abi/predictions.json';
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json';
import autofoxAbi from 'config/abi/autofox.json';
import zapperAbi from 'config/abi/zap.json';
import MultiCallAbi from 'config/abi/Multicall.json';
import JewelAbi from 'config/abi/jewel.json';

const getContract = (abi: any, address: string, signer?: Signer | Provider) => {
  const signerOrProvider = signer ?? simpleRPCProvider;

  return new Contract(address, abi, signerOrProvider);
};

const getHRC20Contract = (address: string, signer?: Signer | Provider): Contract => {
  return getContract(hrc20ABI, address, signer);
};
const getErc721Contract = (address: string, signer?: Signer | Provider): Contract => {
  return getContract(erc721Abi, address, signer);
};
const getIfoV1Contract = (address: string, signer?: Signer | Provider): Contract => {
  return getContract(ifoV1Abi, address, signer);
};
const getIfoV2Contract = (address: string, signer?: Signer | Provider): Contract => {
  return getContract(ifoV2Abi, address, signer);
};
const getSousChefContract = (id: number, signer?: Signer | Provider): Contract => {
  const config = poolsConfig.find(pool => pool.sousId === id);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return getContract(abi, getAddress(config.contractAddress), signer);
};
const getSousChefV2Contract = (id: number, signer?: Signer | Provider): Contract => {
  const config = poolsConfig.find(pool => pool.sousId === id);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return getContract(sousChefV2, getAddress(config.contractAddress), signer);
};
const getPointCenterIfoContract = (signer?: Signer | Provider): Contract => {
  return getContract(pointCenterIfo, getPointCenterIfoAddress(), signer);
};
const getCakeContract = (signer?: Signer | Provider): Contract => {
  return getContract(cakeAbi, getCakeAddress(), signer);
};
const gethMochiAbi = (signer?: Signer | Provider): Contract => {
  return getContract(hMochiAbi, getCakeAddress(), signer);
};
const getProfileContract = (signer?: Signer | Provider): Contract => {
  return getContract(profileABI, getPancakeProfileAddress(), signer);
};
const getPancakeRabbitContract = (signer?: Signer | Provider): Contract => {
  return getContract(pancakeRabbitsAbi, getPancakeRabbitsAddress(), signer);
};
const getBunnyFactoryContract = (signer?: Signer | Provider): Contract => {
  return getContract(bunnyFactoryAbi, getBunnyFactoryAddress(), signer);
};
const getBunnySpecialContract = (signer?: Signer | Provider): Contract => {
  return getContract(bunnySpecialAbi, getBunnySpecialAddress(), signer);
};
const getLotteryContract = (signer?: Signer | Provider): Contract => {
  return getContract(lotteryAbi, getLotteryAddress(), signer);
};
const getBarContract = (signer?: Signer | Provider): Contract => {
  return getContract(barAbi, getBarAddress(), signer);
};
const getLotteryTicketContract = (signer?: Signer | Provider): Contract => {
  return getContract(lotteryTicketAbi, getLotteryTicketAddress(), signer);
};
const getMasterchefContract = (signer?: Signer | Provider): Contract => {
  return getContract(masterChef, getMasterChefAddress(), signer);
};
const getVaultChefContract = (signer?: Signer | Provider): Contract => {
  return getContract(vaultChef, getVaultChefAddress(), signer);
};
const getClaimRefundContract = (signer?: Signer | Provider): Contract => {
  return getContract(claimRefundAbi, getClaimRefundAddress(), signer);
};
const getTradingCompetitionContract = (signer?: Signer | Provider): Contract => {
  return getContract(tradingCompetitionAbi, getTradingCompetitionAddress(), signer);
};
const getEasterNftContract = (signer?: Signer | Provider): Contract => {
  return getContract(easterNftAbi, getEasterNftAddress(), signer);
};
const getCakeVaultContract = (signer?: Signer | Provider): Contract => {
  return getContract(autofoxAbi, getAutoFoxAddress(), signer);
};
const getPredictionsContract = (signer?: Signer | Provider): Contract => {
  return getContract(predictionsAbi, getPredictionsAddress(), signer);
};
const getChainlinkOracleContract = (signer?: Signer | Provider): Contract => {
  return getContract(chainlinkOracleAbi, getChainlinkOracleAddress(), signer);
};
const getAutofoxContract = (signer?: Signer | Provider): Contract => {
  return getContract(autofoxAbi, getAutoFoxAddress(), signer);
};
const getZapperContract = (signer?: Signer | Provider): Contract => {
  return getContract(zapperAbi, getZapperContractAddress(), signer);
};
const getMulticallContract = (signer?: Signer | Provider): Contract => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer);
};
const getTokenContract = (address: string, signer?: Signer | Provider): Contract => {
  return getContract(JewelAbi, address, signer);
};

export {
  getContract,
  getHRC20Contract,
  getErc721Contract,
  getIfoV1Contract,
  getIfoV2Contract,
  getSousChefContract,
  getSousChefV2Contract,
  getPointCenterIfoContract,
  getCakeContract,
  gethMochiAbi,
  getProfileContract,
  getPancakeRabbitContract,
  getBunnyFactoryContract,
  getBunnySpecialContract,
  getLotteryContract,
  getBarContract,
  getLotteryTicketContract,
  getMasterchefContract,
  getVaultChefContract,
  getClaimRefundContract,
  getTradingCompetitionContract,
  getEasterNftContract,
  getCakeVaultContract,
  getPredictionsContract,
  getChainlinkOracleContract,
  getAutofoxContract,
  getZapperContract,
  getMulticallContract,
  getTokenContract
};
